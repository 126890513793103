type InitialState = {
    loading: boolean;
    user: unknown;
    profileDetail: unknown;
};
const initialState: InitialState = {
    loading: true,
    user: {},
    profileDetail: {},
};

interface Data {
    _id: string;
}
interface Action {
    payload: {
        model: string;
        data: Array<unknown> | string | Data | any;
    };
    type: string;
}
const authReducer = (state = initialState, action: Action) => {
    const { payload } = action;

    switch (action.type) {
        // / Single Add  /
        case 'SET_USER':
            return {
                ...state,
                [payload.model]: payload.data,
                loading: false,
            };
        case 'REMOVE_USER':
            return {
                ...state,
                [payload.model]: {},
                loading: false,
            };
        case 'RESET_INITIAL':
            return initialState;
        case 'SET_PERMISSION':
            return {
                ...state,
                permissions: payload.data,
            };

        default:
            return state;
    }
};
export default authReducer;
