import React, { useCallback, useEffect, useReducer } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { reducer, initialState } from '../reducer';

import CustomAutomplete from '@/components/Default/Autocomplete';
import { REQUEST } from '@/store/interfaces';
import { useRequest, useLoading } from '@/components/App';
import { useTranslate } from '@/components/Translate';
import Modal from '@/components/Default/Modal';
import AddCarrierForm from '@/pages/Carrier/Components/CarrierForm';

const FormikSchemaUser = Yup.object().shape({
    users: Yup.array().of(Yup.string()).min(1, 'User is empty!').max(50, 'Too Long!').required('Required'),
});

const FormikSchemaSAP = Yup.object().shape({
    suppliers: Yup.array()
        .of(Yup.string())
        .when('isIntersac', {
            is: false,
            then: Yup.array().min(1, 'Transport Carriers is empty!').max(50, 'Too Long!').required('Required'),
        }),
    truckerId: Yup.string().when('isIntersac', {
        is: true,
        then: Yup.string().required('Required'),
    }),
    // suppliersProductId: Yup.string().required('Service supplieris empty'),
});

interface PROPS {
    data: { firstname: string; _id: string; email: string }[];
    supplierProductData: { ItemName: string; _id: string; ItemCode: string }[];
    loadId: string | string[];
    loadSAPType: boolean;
    isIntersac?: boolean;
    onSubmit: (type?: string) => void;
    onFormSubmit?: (formData: {
        quoteSuppliers: never[];
        suppliersProduct: string;
        quoteUsers: never[];
        _id: string | string[];
        emails: string;
        note: string;
    }) => void;
    submitText?: string;
}
interface Carrier {
    firstname: string;
    _id: string;
    email: string;
}
function AllQuoteUserList(props: PROPS) {
    const { trans } = useTranslate();
    const { request, loading } = useRequest();
    const { ButtonLoader } = useLoading();
    const [state, dispatch] = useReducer(reducer, initialState);

    const closeModal = (key: string) => {
        dispatch({ type: 'SET_DATA', data: { ...state, [key]: false } });
    };

    const addNewCarrier = () => {
        dispatch({
            type: 'SET_DATA',
            data: {
                ...state,
                newCarrierModal: true,
            },
        });
    };

    const getTruckerData = useCallback(async () => {
        const res = (await request('GET_DEFAULT_DATA_MODEL', {
            pathname: '/user-manager/get-all-users',
            model: 'allusers',
            filter: {
                type: ['fleetTrucker'],
                truckerType: true,
            },
        })) as REQUEST;

        if (res.status) {
            dispatch({
                type: 'SET_DATA',
                data: {
                    ...state,
                    truckerData: res.data,
                },
            });
        }
    }, []);
    const searchCarriers = useCallback(async (tag: string) => {
        const res = (await request('GET_DEFAULT_DATA_MODEL', {
            pathname: '/user-manager/get-users-sap',
            model: 'allusers',
            tag,
        })) as REQUEST;

        if (res.status) {
            const carrierData = res.data as Carrier;
            dispatch({
                type: 'SET_DATA',
                data: {
                    ...state,
                    supplierData: carrierData,
                },
            });
        }
    }, []);

    useEffect(() => {
        searchCarriers('');
        getTruckerData();
    }, []);
    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    isIntersac: props?.isIntersac || false,
                    users: [],
                    suppliers: [],
                    quoteUsers: [],
                    truckerId: '',
                    quoteSuppliers: [],
                    suppliersProductId: '',
                    emails: '',
                    note: '',
                    email_cc: '',
                }}
                validationSchema={props.loadSAPType ? FormikSchemaSAP : FormikSchemaUser}
                onSubmit={async (values, { resetForm }) => {
                    const payload = {
                        quoteSuppliers: values.quoteSuppliers,
                        suppliersProduct: values.suppliersProductId,
                        quoteUsers: values.quoteUsers,
                        _id: props.loadId,
                        emails: values.emails,
                        // email_cc: values.email_cc,
                        note: values.note,
                    };

                    if (props.onFormSubmit) {
                        props.onFormSubmit(payload);
                        resetForm();
                        return;
                    }

                    if (values.isIntersac) {
                        const carrierId = state.supplierData.filter((item: Carrier) => item.firstname === 'Intersac');
                        const res = (await request('ORDERS_STATUS_UPDATE', {
                            _id: props.loadId,
                            status: 'assigned',
                            truckerId: values.truckerId,
                            carrierId: carrierId[0]._id,
                        })) as REQUEST;
                        if (res.status) {
                            resetForm();
                            if (props.onSubmit) props.onSubmit('order');
                        }
                    } else {
                        const res = (await request('LOADS_SEND_QUOTES_TO_USERS', payload)) as REQUEST;
                        if (res.status) {
                            resetForm();
                            if (props.onSubmit) props.onSubmit();
                        }
                    }
                }}
            >
                {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {state.supplierData?.length ? (
                            <>
                                <Row>
                                    <Col xl={12} lg={12}>
                                        <p onClick={() => addNewCarrier()}>Add New Carrier</p>
                                    </Col>
                                    {/* <Col xl={2} lg={2}>
                                        <Form.Group className="tableCheck mb-3" controlId="formBasicCheckbox">
                                            <Form.Check
                                                required
                                                type="checkbox"
                                                name="isIntersac"
                                                onChange={handleChange}
                                                isInvalid={!!errors.isIntersac}
                                                feedback={errors.isIntersac}
                                                feedbackType="invalid"
                                                checked={values.isIntersac}
                                                label={<span style={{ marginLeft: '10px' }}>Intersac</span>}
                                            />
                                            {errors.isIntersac ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.isIntersac}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                                {/* Empty Row for spacing */}
                                <Row>
                                    <Col>{/* Empty Col */}</Col>
                                </Row>
                                {values.isIntersac ? (
                                    <Row>
                                        <Col md={12}>
                                            <CustomAutomplete
                                                label={'Trucker'}
                                                placeholder={'Trucker'}
                                                type="text"
                                                loading={false}
                                                // onClick={() => disabledPairedFields()}
                                                clearOption={() => {
                                                    setFieldValue('truckerId', '');
                                                }}
                                                name={'truckerId'}
                                                multiple={false}
                                                onSelect={(e, val) => {
                                                    setFieldValue('truckerId', val._id);
                                                    return val.firstname as string;
                                                }}
                                                isOptionsEmpty={false}
                                                filter={true} //If not remote
                                                //Provide only if we want to render a value again
                                                value={
                                                    values.truckerId
                                                        ? state?.truckerData?.find(
                                                              (vl: { _id: string }) => vl._id === values.truckerId,
                                                          )?.firstname || ''
                                                        : ''
                                                }
                                                data={state?.truckerData}
                                                filterfrom={val =>
                                                    `${val.firstname}, ${val.email} [${val.type}]` as string
                                                }
                                                getvalue={val =>
                                                    `${val.firstname}, ${val.email} [${val.type}]` as string
                                                }
                                                renderValue={val =>
                                                    state?.truckerData?.find(
                                                        (vl: { firstname: string }) => vl.firstname === val,
                                                    )?.firstname || ''
                                                }
                                            />
                                            {errors.truckerId ? (
                                                <Form.Text className="text-danger">{errors.truckerId}</Form.Text>
                                            ) : null}
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row>
                                        {/* // TODO Change SAP Supplier Show */}
                                        {props.loadSAPType ? (
                                            <>
                                                <Col md={12}>
                                                    <CustomAutomplete
                                                        label={'Transport Carriers'}
                                                        placeholder={'Transport Carriers'}
                                                        type="text"
                                                        loading={false}
                                                        // onClick={() => disabledPairedFields()}
                                                        clearOption={() => {
                                                            setFieldValue('suppliers', []);
                                                        }}
                                                        name={'suppliers'}
                                                        multiple={true}
                                                        onMultipleSelect={selected => {
                                                            setFieldValue('suppliers', [
                                                                ...selected.map(mp => mp.name),
                                                            ]);
                                                            const quoteUsersIds = selected.map(mp => {
                                                                if (
                                                                    state.supplierData &&
                                                                    state.supplierData.some(
                                                                        (sm: Carrier) => sm.email === mp.name,
                                                                    )
                                                                ) {
                                                                    return state.supplierData?.find(
                                                                        (sm: Carrier) => sm.email === mp.name,
                                                                    )?._id;
                                                                }
                                                            });
                                                            setFieldValue('suppliers', [
                                                                ...selected.map(mp => mp.name),
                                                            ]);
                                                            setFieldValue('quoteSuppliers', [...quoteUsersIds]);
                                                        }}
                                                        onSelect={(e, val) => val.email as string}
                                                        isOptionsEmpty={false}
                                                        filter={true} //If not remote
                                                        //Provide only if we want to render a value again
                                                        values={values.suppliers?.map(mp => ({ name: mp })) || []}
                                                        data={state.supplierData}
                                                        onChange={e => searchCarriers(e.target.value)}
                                                        filterfrom={val => val.firstname as string}
                                                        getvalue={val => `${val.firstname}, ${val.email}` as string}
                                                        renderValue={val =>
                                                            state?.supplierData?.find((vl: Carrier) => vl.email === val)
                                                                ?.firstname || ''
                                                        }
                                                    />
                                                    {errors.suppliers ? (
                                                        <Form.Text className="text-danger">
                                                            {errors.suppliers}
                                                        </Form.Text>
                                                    ) : null}
                                                </Col>
                                                {/* <Col md={12}>
                        <CustomAutomplete
                          label={'Service supplier'}
                          placeholder={'Service supplier'}
                          type="text"
                          loading={false}
                          // onClick={() => disabledPairedFields()}
                          clearOption={() => {
                            setFieldValue('suppliersProductId', '');
                          }}
                          name={'suppliersProductId'}
                          multiple={false}
                          onSelect={(e, val) => {
                            setFieldValue('suppliersProductId', val._id);
                            return val.ItemName as string;
                          }}
                          isOptionsEmpty={false}
                          filter={true} //If not remote
                          //Provide only if we want to render a value again
                          value={
                            values.suppliersProductId
                              ? props?.supplierProductData?.find(vl => vl._id === values.suppliersProductId)
                                  ?.ItemName || ''
                              : ''
                          }
                          data={props.supplierProductData}
                          filterfrom={val => val.ItemName as string}
                          getvalue={val => `${val.ItemName}` as string}
                          renderValue={val =>
                            props?.supplierProductData?.find(vl => vl.ItemName === val)?.ItemName || ''
                          }
                        />
                        {errors.suppliersProductId ? (
                          <Form.Text className="text-danger">{errors.suppliersProductId}</Form.Text>
                        ) : null}
                      </Col> */}
                                            </>
                                        ) : (
                                            <Col md={12}>
                                                <CustomAutomplete
                                                    label={'Users'}
                                                    placeholder={'Users'}
                                                    type="text"
                                                    loading={false}
                                                    // onClick={() => disabledPairedFields()}
                                                    clearOption={() => {
                                                        setFieldValue('users', []);
                                                    }}
                                                    name={'users'}
                                                    multiple={true}
                                                    onMultipleSelect={selected => {
                                                        setFieldValue('users', [...selected.map(mp => mp.name)]);
                                                        const quoteUsersIds = selected.map(mp => {
                                                            if (
                                                                props.data &&
                                                                props.data.some(sm => sm.email === mp.name)
                                                            ) {
                                                                return props.data?.find(sm => sm.email === mp.name)
                                                                    ?._id;
                                                            }
                                                        });
                                                        setFieldValue('users', [...selected.map(mp => mp.name)]);
                                                        setFieldValue('quoteUsers', [...quoteUsersIds]);
                                                    }}
                                                    onSelect={(e, val) => val.email as string}
                                                    isOptionsEmpty={false}
                                                    filter={true} //If not remote
                                                    //Provide only if we want to render a value again
                                                    values={values.users?.map(mp => ({ name: mp })) || []}
                                                    data={props.data}
                                                    filterfrom={val => val.firstname as string}
                                                    getvalue={val =>
                                                        `${val.firstname}, ${val.email} [${val.type}]` as string
                                                    }
                                                    renderValue={val =>
                                                        props?.data?.find(vl => vl.email === val)?.firstname +
                                                            ' - ' +
                                                            val || ''
                                                    }
                                                />
                                                {errors.users ? (
                                                    <Form.Text className="text-danger">{errors.users}</Form.Text>
                                                ) : null}
                                            </Col>
                                        )}

                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="formBasictext">
                                                <Form.Label>{trans('Other Users')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={trans('Email Ids not in System')}
                                                    onChange={handleChange}
                                                    name="emails"
                                                    value={values.emails}
                                                    isInvalid={!!errors?.emails}
                                                />
                                                <Form.Text muted>
                                                    Please Provide multiple email Ids Comma Separated
                                                </Form.Text>
                                                {errors?.emails ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors?.emails}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Label>{trans('CC')}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={trans('CC Quote to User')}
                        onChange={handleChange}
                        name="email_cc"
                        value={values.email_cc}
                        isInvalid={!!errors?.email_cc}
                      />
                      <Form.Text muted>Please Provide multiple email Ids Comma Separated</Form.Text>
                      {errors?.email_cc ? (
                        <Form.Control.Feedback type="invalid">{errors?.email_cc}</Form.Control.Feedback>
                      ) : null}
                    </Form.Group>
                  </Col> */}
                                        <Col md={12}>
                                            <Form.Group className="mb-3" controlId="formBasictext">
                                                <Form.Label>{trans('Note')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder={trans('Provide any Note to user for Quote')}
                                                    onChange={handleChange}
                                                    name="note"
                                                    value={values.note}
                                                    isInvalid={!!errors?.note}
                                                />
                                                {errors?.note ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors?.note}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )}

                                <Col md={12} className="pt-3">
                                    <Button type="submit" className="customBtn w-100">
                                        {loading?.LOADS_SEND_QUOTES_TO_USERS_LOADING
                                            ? ButtonLoader()
                                            : trans(
                                                  props?.submitText
                                                      ? props?.submitText
                                                      : values.isIntersac
                                                      ? 'Update Order'
                                                      : 'SEND',
                                              )}
                                    </Button>
                                </Col>
                            </>
                        ) : (
                            <p>No Users Found</p>
                        )}
                    </Form>
                )}
            </Formik>
            <Modal
                id="carrier"
                title={'Add Carrier'}
                width="45%"
                show={Boolean(state?.newCarrierModal)}
                onClose={() => closeModal('newCarrierModal')}
            >
                <div className="container">
                    <AddCarrierForm component={() => closeModal('newCarrierModal')} />
                </div>
            </Modal>
        </div>
    );
}

export default AllQuoteUserList;
