import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
interface Props {
    handleReset: () => void;
    handleGetRates: () => void;
    handleSaveQuote: () => void;
}
const Footer = ({ handleReset, handleGetRates, handleSaveQuote }: Props) => {
    return (
        <Container
            fluid
            className="bg-light py-3 mt-5"
            style={{
                marginLeft: '1%',
                marginTop: '1%',
            }}
        >
            <Row className="d-flex justify-content-center align-items-center">
                <Col sm="12" className="d-flex justify-content-center gap-3">
                    <Button variant="secondary" size="md" style={{ width: '200px' }} onClick={handleReset}>
                        Reset All Details
                    </Button>
                    <Button variant="primary" size="md" style={{ width: '200px' }} onClick={handleSaveQuote}>
                        Save Quote
                    </Button>
                    <Button variant="success" size="md" style={{ width: '200px' }} onClick={handleGetRates}>
                        Get Rates
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Footer;
