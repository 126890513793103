const STATUS = {
    SET_AUTHENTICATED: 'SET_AUTHENTICATED',
    ERROR: 'CATCH_ERROR',
    USER_ERROR: 'USER_ERROR',
    SUCCESS: 'SUCCESS',
    UPDATING: 'UPDATING',
    WARNING: 'WARNING',
    CLEAR_ERRORS: 'CLEAR_ERRORS',
};

const USERS = {
    SET_USER: 'SET_USER',
};

const CONSTANTS = {
    LOADING: 'LOADING',
    MODAL_OPEN: 'MODAL_OPEN',
    LOADING_STOP: 'LOADING_STOP',
    GET_DEFAULT_DATA_MODEL: 'GET_DEFAULT_DATA_MODEL',
    // Colony
    LOAD_DEFAULT_GET_ALL_SUCCESS: 'LOAD_DEFAULT_GET_ALL_SUCCESS',
    LOAD_DEFAULT_UPDATE_ALL_SUCCESS: 'LOAD_DEFAULT_UPDATE_ALL_SUCCESS',
    LOAD_DEFAULT_ADD_ALL_SUCCESS: 'LOAD_DEFAULT_ADD_ALL_SUCCESS',
    LOAD_DEFAULT_ADD_ALL_SUCCESS_ARRAY_DATA: 'LOAD_DEFAULT_ADD_ALL_SUCCESS_ARRAY_DATA',
    LOAD_DEFAULT_UPDATE_ALL_SUCCESS_ARRAY_DATA: 'LOAD_DEFAULT_UPDATE_ALL_SUCCESS_ARRAY_DATA',
    LOAD_DEFAULT_DELETE_ALL_SUCCESS: 'LOAD_DEFAULT_DELETE_ALL_SUCCESS',
    LOAD_DEFAULT_GET_ALL_FAILURE: 'LOAD_DEFAULT_GET_ALL_FAILURE',
    // Users
    USER_LOADING: 'USER_LOADING',
    USER_LOADING_STOP: 'USER_LOADING_STOP',
    LOAD_USER_FETCH_SUCCESS: 'LOAD_USER_FETCH_SUCCESS',
    LOAD_USER_LOGOUT_SUCCESS: 'LOAD_USER_LOGOUT_SUCCESS',
    LOAD_USER_ADD_ALL_SUCCESS: 'LOAD_USER_ADD_ALL_SUCCESS',
    LOAD_USER_UPDATE_ALL_SUCCESS: 'LOAD_USER_UPDATE_ALL_SUCCESS',
    // Profile
    PROFILE_GET_USER_DETAIL: 'PROFILE_GET_USER_DETAIL',
    PROFILE_READ_NOTIFICATION: 'PROFILE_READ_NOTIFICATION',
    PROFILE_UPDATE_USER_DETAIL: 'PROFILE_UPDATE_USER_DETAIL',
    PROFILE_UPDATE_USER_PASSWORD: 'PROFILE_UPDATE_USER_PASSWORD',
    // Trucks
    TRUCKS_GET_LIST: 'TRUCKS_GET_LIST',
    TRUCKS_CREATE_USER: 'TRUCKS_CREATE_USER',
    TRUCKS_GET_EQUIPMENT_LIST: 'TRUCKS_GET_EQUIPMENT_LIST',
    TRUCKS_GET_USER_DETAIL: 'TRUCKS_GET_USER_DETAIL',
    TRUCKS_UPDATE_USER: 'TRUCKS_UPDATE_USER',
    TRUCKS_UPDATE_USER_LOCATION: 'TRUCKS_UPDATE_USER_LOCATION',
    TRUCKS_UPDATE_INFORMATION: 'TRUCKS_UPDATE_INFORMATION',
    TRUCKS_REMOVE_USER: 'TRUCKS_REMOVE_USER',
    // Favourites
    FAVOURITES_ADD: 'FAVOURITES_ADD',
    FAVOURITES_REMOVE: 'FAVOURITES_REMOVE',
    // Addresses
    ADDRESS_FETCH: 'ADDRESS_FETCH',
    ADDRESS_ADD: 'ADDRESS_ADD',
    ADDRESS_ADD_LANE: 'ADDRESS_ADD_LANE',
    ADDRESS_UPDATE_LANE: 'ADDRESS_UPDATE_LANE',
    ADDRESS_REMOVE_LANE: 'ADDRESS_REMOVE_LANE',
    ADDRESS_FETCH_LANES: 'ADDRESS_FETCH_LANES',
    ADDRESS_FETCH_DETAIL_LANE: 'ADDRESS_FETCH_DETAIL_LANE',
    ADDRESS_FETCH_USERS_BY_LANE: 'ADDRESS_FETCH_USERS_BY_LANE',
    ADDRESS_FETCH_PAST_LOADS_BY_LANE: 'ADDRESS_FETCH_PAST_LOADS_BY_LANE',
    ADDRESS_FETCH_CURRENT_LOADS_BY_LANE: 'ADDRESS_FETCH_CURRENT_LOADS_BY_LANE',
    ADDRESS_DETAIL: 'ADDRESS_DETAIL',
    ADDRESS_UPDATE: 'ADDRESS_UPDATE',
    ADDRESS_REMOVE: 'ADDRESS_REMOVE',
    ADDRESS_FETCH_BY_TAG: 'ADDRESS_FETCH_BY_TAG',
    WAREHOUSE_BY_TAG: 'WAREHOUSE_BY_TAG',
    CUSTOMER_SAP_BY_TAG: 'CUSTOMER_SAP_BY_TAG',
    WAREHOUSE_GET_AVAILABLE_PALLETS: 'WAREHOUSE_GET_AVAILABLE_PALLETS',
    WAREHOUSE_GET_AVAILABLE_PRODUCTS: 'WAREHOUSE_GET_AVAILABLE_PRODUCTS',
    CLEAR_ADDRESSES: 'CLEAR_ADDRESSES',
    CLEAR_PALLETS: 'CLEAR_PALLETS',
    CLEAR_AVAILABLE_PRODUCTS: 'CLEAR_AVAILABLE_PRODUCTS',
    WAREHOUSE_REMOVE: 'WAREHOUSE_REMOVE',
    WAREHOUSE_DETAIL: 'WAREHOUSE_DETAIL',
    WAREHOUSE_ADD: 'WAREHOUSE_ADD',
    WAREHOUSE_UPDATE: 'WAREHOUSE_UPDATE',
    // SEARCH TRUCKS
    SEARCHTRUCK_FILTER: 'SEARCHTRUCK_FILTER',
    // LOADS
    LOADS_REMOVE: 'LOADS_REMOVE',
    LOADS_ADD: 'LOADS_ADD',
    LOADS_UPDATE: 'LOADS_UPDATE',
    GET_GRAPH_ACTIVITY: 'GET_GRAPH_ACTIVITY',
    LOADS_DETAIL_UPDATE: 'LOADS_DETAIL_UPDATE',
    LOADS_DETAIL_STATUS_UPDATE: 'LOADS_DETAIL_STATUS_UPDATE',
    LOADS_GET_LOAD_DETAIL: 'LOADS_GET_LOAD_DETAIL',
    TRACK_DETAIL_BY_LOAD_ID: 'TRACK_DETAIL_BY_LOAD_ID',
    TRACK_LOCATION_TRUCKER_ID: 'TRACK_LOCATION_TRUCKER_ID',
    LOADS_GET_SUBLOAD_DETAIL: 'LOADS_GET_SUBLOAD_DETAIL',
    LOADS_ASSIGN_TRUCK: 'LOADS_ASSIGN_TRUCK',
    LOADS_AVAILABLE_TRUCKERS: 'LOADS_AVAILABLE_TRUCKERS',
    LOADS_GET_LOAD_DETAILS: 'LOADS_GET_LOAD_DETAILS',
    LOADS_BROADCAST: 'LOADS_BROADCAST',
    LOADS_SEND_QUOTES_TO_USERS: 'LOADS_SEND_QUOTES_TO_USERS',
    LOADS_GET_SAP_PRODUCTS: 'LOADS_GET_SAP_PRODUCTS',
    LOADS_GET_DEFAUlT_ADDRESSS: 'LOADS_GET_DEFAUlT_ADDRESSS',
    LOADS_GET_LOAD_DETAIL_BY_LOADID: 'LOADS_GET_LOAD_DETAIL_BY_LOADID',
    // Search Load
    SEARCHLOAD_FILTER: 'SEARCHLOAD_FILTER',
    SEARCHLOAD_ACEEPT_BY_FLEET: 'SEARCHLOAD_ACEEPT_BY_FLEET',
    // Dashboard
    DASHBOARD_FETCH_ORDERS_COUNT: 'DASHBOARD_FETCH_ORDERS_COUNT',
    DASHBOARD_FETCH_TRUCK_COUNT: 'DASHBOARD_FETCH_TRUCK_COUNT',
    DASHBOARD_FETCH_LATEST_ORDERS_LIST: 'DASHBOARD_FETCH_LATEST_ORDERS_LIST',
    DASHBOARD_FETCH_POPULAR_ROUTES_LIST: 'DASHBOARD_FETCH_POPULAR_ROUTES_LIST',
    DASHBOARD_FETCH_TOP_RATED_TRUCKS: 'DASHBOARD_FETCH_TOP_RATED_TRUCKS',
    DASHBOARD_FETCH_TRUCKS_NOT_DELIVERED: 'DASHBOARD_FETCH_TRUCKS_NOT_DELIVERED',
    DASHBOARD_FETCH_RECENT_ACTIVITIES: 'DASHBOARD_FETCH_RECENT_ACTIVITIES',

    // Orders
    ORDERS_ACCEPT_ADD_ACCESORIAL: 'ORDERS_ACCEPT_ADD_ACCESORIAL',
    ORDERS_CONFIRM_BY_FLEET: 'ORDERS_CONFIRM_BY_FLEET',
    ORDERS_ASSIGN_TO_TRUCK: 'ORDERS_ASSIGN_TO_TRUCK',
    ORDERS_DETAIL_STATUS_UPDATE: 'ORDERS_DETAIL_STATUS_UPDATE',
    ORDERS_DETAIL_UPDATE_STATUS_PUBLIC: 'ORDERS_DETAIL_UPDATE_STATUS_PUBLIC',
    ORDERS_STATUS_UPDATE: 'ORDERS_STATUS_UPDATE',
    ORDERS_SEND_INVOICE: 'ORDERS_SEND_INVOICE',
    ORDERS_MARK_PAID: 'ORDERS_MARK_PAID',
    ORDERS_CONFIRMATION_PDF: 'ORDERS_CONFIRMATION_PDF',
    ORDERS_INVOICE_ORDER_PDF: 'ORDERS_INVOICE_ORDER_PDF',
    ORDERS_BOL_ORDER_PDF: 'ORDERS_BOL_ORDER_PDF',
    ORDERS_DETAIL_REJECT: 'ORDERS_DETAIL_REJECT',
    ORDERS_NEXT_PREVIOUS: 'ORDERS_NEXT_PREVIOUS',
    GET_SAP_ORDER: 'GET_SAP_ORDER',
    ORDERS_RESET: 'ORDERS_RESET',
    ORDERS_DETAIL_BY_ID: 'ORDERS_DETAIL_BY_ID',
    CREATE_SALES_ORDER: 'CREATE_SALES_ORDER',
    // Quote
    QUOTE_CONFIRMED: 'QUOTE_CONFIRMED',
    QUOTE_DECLINED: 'QUOTE_DECLINED',
    QUOTE_APPROVED: 'QUOTE_APPROVED',
    QUOTE_DETAIL_BY_ID: 'QUOTE_DETAIL_BY_ID',
    QUOTE_DETAIL_BY_ID_FOR_ADMIN: 'QUOTE_DETAIL_BY_ID_FOR_ADMIN',
    QUOTE_VALIDATE_PASSCODE: 'QUOTE_VALIDATE_PASSCODE',
    QUOTE_SEND_COMMENT: 'QUOTE_SEND_COMMENT',
    QUOTE_SEND_COMMENT_SENDER: 'QUOTE_SEND_COMMENT_SENDER',
    QUOTE_ALL_CANCEL: 'QUOTE_ALL_CANCEL',
    // Purchase Order
    PURCHASE_ORDER_FETCH: 'PURCHASE_ORDER_FETCH',
    PURCHASE_ORDER_UPDATE: 'PURCHASE_ORDER_UPDATE',
    PURCHASE_ORDER_DETAIL: 'PURCHASE_ORDER_DETAIL',
    PURCHASE_ORDER_GET_SAP: 'PURCHASE_ORDER_GET_SAP',
    PURCHASE_ORDER_VIZION_CANTAINER_LOCATION: 'PURCHASE_ORDER_VIZION_CANTAINER_LOCATION',
    PURCHASE_ORDER_VIZION_CANTAINER: 'PURCHASE_ORDER_VIZION_CANTAINER',
    PURCHASE_ORDER_CONTAINER_UPDATE: 'PURCHASE_ORDER_CONTAINER_UPDATE',
    CLOSE_PURCHASE_ORDER: 'CLOSE_PURCHASE_ORDER',
    CREATE_PURCHASE_ORDER: 'CREATE_PURCHASE_ORDER',
    TRACK_PURCHASE_ORDER: 'TRACK_PURCHASE_ORDER',
    GET_PURCHASE_ORDER: 'GET_PURCHASE_ORDER',
    UPDATE_PURCHASE_ORDER: 'UPDATE_PURCHASE_ORDER',
    SPLIT_PURCHASE_ORDER: 'SPLIT_PURCHASE_ORDER',
    // Calendar
    CALENDAR_UPDATE_DATE: 'CALENDAR_UPDATE_DATE',
    DELIVERY_SCHEDULE_GET_DETAILS: 'DELIVERY_SCHEDULE_GET_DETAILS',
    DELIVERY_SCHEDULE_UPDATE_DATE: 'DELIVERY_SCHEDULE_UPDATE_DATE',
    // Push Notification
    WEB_PUSH_SEND_MESSAGE: 'WEB_PUSH_SEND_MESSAGE',
    ...STATUS,
    ...USERS,
    // Permissions
    GET_USER_PERMISSION: 'GET_USER_PERMISSION',
};

export default CONSTANTS;
