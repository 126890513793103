import React, { useEffect } from 'react';
import './App.scss';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import routes from './routes';

import { ChatProvider } from '@/components/Chat/context';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { LoadLazy, validateAuthentication } from '@/routes/AuthRoute';
import store from '@/store';
import { toastr } from '@/utils/helpers';
import Unauthorized from './routes/Unauthorized';
import { PermissionsProvider } from './pages/Container/PermissonContext';

// Validate Authentication For User
validateAuthentication();

function App() {
    useEffect(() => {
        function showPosition() {
            console.log('Permission Granted');
        }
        const timeout = setTimeout(() => {
            if (window.navigator) {
                navigator.permissions.query({ name: 'geolocation' }).then(result => {
                    if (result.state === 'prompt') {
                        navigator.geolocation.getCurrentPosition(showPosition);
                    } else if (result.state === 'denied') {
                        toastr('Geolocation Permission denied', 'info');
                    }
                });
            }
        }, 5000);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <CookiesProvider>
                    <BrowserRouter>
                        <ChatProvider>
                            <PermissionsProvider>
                                <Routes>
                                    <Route path="/unauthorized" element={<Unauthorized />} />

                                    {routes.map((route, key) => (
                                        <React.Fragment key={key}>
                                            <Route path={route.path} element={LoadLazy(route)} />
                                            {route.children &&
                                                route.children.map((subRoute, subKey) => (
                                                    <Route
                                                        key={subKey}
                                                        path={route.path + subRoute.path}
                                                        element={LoadLazy(subRoute)}
                                                    />
                                                ))}
                                        </React.Fragment>
                                    ))}
                                </Routes>
                            </PermissionsProvider>
                        </ChatProvider>
                    </BrowserRouter>
                </CookiesProvider>
            </Provider>
        </ErrorBoundary>
    );
}

export default App;
