import React from 'react';
import CheckboxWithTooltip from './Checkbox';
import { Col, Row } from 'react-bootstrap';
interface CheckboxOption {
    label: string;
    tooltipText?: string;
    checked: boolean;
    value: string;
}
interface CheckboxGroupProps {
    options: CheckboxOption[];
    onChange: (e: CheckboxOption) => void;
}
const CheckboxGroup = ({ options, onChange }: CheckboxGroupProps) => (
    <Row className="mb-3">
        {options.map((option, index) => (
            <Col key={index} md={6} className="mb-2">
                <CheckboxWithTooltip
                    label={option.label}
                    tooltipText={option.tooltipText}
                    checked={option.checked}
                    onChange={e =>
                        onChange({
                            ...option,
                        })
                    }
                />
            </Col>
        ))}
    </Row>
);

export default CheckboxGroup;
