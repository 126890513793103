import React, { useState, memo, useEffect } from 'react';

import { getAutoCompleteOptions } from './service';

import CustomAutomplete from '@/components/Default/Autocomplete';

interface ADDRESS {
    [key: string]: unknown;
}

interface PROPS {
    name?: string;
    type?: string;
    autoComplete?: string;
    placeholder?: string;
    onChange?: any;
    value?: string;
    isInvalid?: boolean;
    onSelectOption?: (address: ADDRESS) => void;
    errors?: string;
}

function index({ onSelectOption, ...rest }: PROPS) {
    const [placeValue, setplaceValue] = useState('');
    const [autoComplete, setautoComplete] = useState<any>(null);

    const formatAutocompleteAddress = (place: ADDRESS) => {
        const data: ADDRESS = {
            lat: '',
            lng: '',
            formattedAddress: '',
            city: '',
            state: '',
            country: '',
            postalCode: '',
        };
        if (place.geometry !== undefined) {
            const placeGeoLocation = place.geometry as any;
            if (placeGeoLocation?.coordinates?.length) {
                data['lat'] = placeGeoLocation.coordinates[1] || 0;
                data['lng'] = placeGeoLocation.coordinates[0] || 0;
            }
        }
        data['formattedAddress'] = place?.place_name || '';
        if (place?.context) {
            const addressComponent = place?.context as any;
            for (const addr of addressComponent) {
                if (addr?.id) {
                    const typeofAddress = addr?.id;
                    if (typeofAddress.includes('region')) data['state'] = addr.text;
                    if (typeofAddress.includes('district')) data['city'] = addr.text;
                    if (typeofAddress.includes('country')) data['country'] = addr.text;
                    if (typeofAddress.includes('postal_code')) data['postalCode'] = addr.text;
                }
            }
        }
        return data;
    };
    const onPlaceChanged: any = (address: ADDRESS) => {
        if (onSelectOption) onSelectOption(address);
    };
    const handleChange = async (event: React.ChangeEvent) => {
        if (rest?.onChange) rest?.onChange(event);
        const { value } = event.target as HTMLInputElement;

        setplaceValue(value);
    };

    useEffect(() => {
        const timer = setTimeout(async () => {
            try {
                const searchResult = await getAutoCompleteOptions(placeValue);

                if (searchResult) {
                    const places = [];
                    for (const place of searchResult) {
                        const formateddAddress = formatAutocompleteAddress(place);

                        if (formateddAddress) places.push(formateddAddress);
                    }
                    setautoComplete(places);
                }
            } catch (error) {
                console.log(error);
            }
        }, 300);
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [placeValue]);

    return (
        <div>
            <CustomAutomplete
                label={''}
                placeholder={rest.placeholder || 'Autocomplete'}
                type="text"
                loading={false}
                // onClick={() => disabledPairedFields()}
                clearOption={() => {
                    console.log('klg-108');
                }}
                name={`auto`}
                //onSelect={(e, val) => val.details as string}
                onSelect={(_: any, val: any) => {
                    if (onPlaceChanged) onPlaceChanged(val);
                    return val.formattedAddress as string;
                }}
                isOptionsEmpty={false}
                //   value={load.details}
                onChange={e => {
                    handleChange(e);
                }}
                data={autoComplete ?? []}
                //   filterfrom={val => val.ItemName as string}
                getvalue={val => val.formattedAddress as string}
            />
        </div>
    );
}

export default memo(index);
