import React, { useRef, useState, forwardRef, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BsArrowLeftRight } from 'react-icons/bs';
import { Country } from 'country-state-city';
import { State } from 'country-state-city';
import '../styles.css';

// Validation schema
const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    street: Yup.string().required('Street Address is required'),
    city: Yup.string().required('City is required'),
    country: Yup.string().required('Country is required'),
    building: Yup.string().required('Building No is required'),
    state: Yup.string().required('State is required'),
    locationType: Yup.string().required('Location Type is required'),
    phone: Yup.string().required('Phone Number is required'),
    postalCode: Yup.string().required('Postal Code is required'),
    pickupDate: Yup.string().when('formType', {
        is: 'from',
        then: Yup.string().required('Pickup date is required'),
    }),
    pickupTime: Yup.string().when('formType', {
        is: 'from',
        then: Yup.string().required('Pickup time is required'),
    }),
    dropoffDate: Yup.string().when('formType', {
        is: 'to',
        then: Yup.string().required('Drop off date is required'),
    }),
    dropoffTime: Yup.string().when('formType', {
        is: 'to',
        then: Yup.string().required('Drop off time is required'),
    }),
});

interface ShippingFormProps {
    showResidentialAddressCheckbox?: boolean;
    onChange?: (values: any) => void;
    formType: 'to' | 'from';
}

const ShippingFormW = ({ showResidentialAddressCheckbox = true, onChange, formType }: ShippingFormProps, ref) => {
    const [selectedCountry, setSelectedCountry] = useState('');

    const formik = useFormik({
        initialValues: {
            name: '',
            street: '',
            city: '',
            country: '',
            building: '',
            state: '',
            locationType: '',
            phone: '',
            postalCode: '',
            residentialAddress: false,
            stateSelect: '',
            countrySelect: '',
            pickupDate: '',
            pickupTime: '',
            dropoffDate: '',
            dropoffTime: '',
            formType: formType,
        },
        validationSchema,
        onSubmit: values => {
            console.log('Form Data:', values);
        },
    });

    // Expose formik instance through ref
    React.useImperativeHandle(ref, () => ({
        values: formik.values,
        resetForm: () => {
            formik.resetForm();
            setSelectedCountry(''); // Reset the country state
            formik.setFieldValue('countrySelect', ''); // Reset the formik country field
        },
        validateForm: formik.validateForm,
        isValid: formik.isValid,
        getFormikInstance: () => formik,
        setFieldValue: formik.setFieldValue,
    }));

    // Call onChange whenever form values change

    useEffect(() => {
        if (onChange) {
            onChange(formik.values);
        }
    }, [JSON.stringify(formik.values)]);

    const locationsTypes = [
        {
            value: 'commercial-business-with-dock',
            label: 'Business - Tailgate Not Required',
        },
        {
            value: 'commercial-business-with-tailgate-required',
            label: 'Business - Tailgate Required',
        },
        {
            value: 'residential-with-tailgate-required',
            label: 'Residence - Tailgate Required',
        },
        {
            value: 'residential-without-tailgate-required',
            label: 'Residence - Tailgate Not Required',
        },
    ];
    const countries = Country.getAllCountries();
    const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];

    return (
        <Form onSubmit={formik.handleSubmit} className="p-4 border rounded bg-light">
            <Row>
                <Col md={12}>
                    <Form.Group className="mb-3">
                        <Form.Label style={{ color: 'black' }}>Name *</Form.Label>
                        <Form.Control
                            name="name"
                            {...formik.getFieldProps('name')}
                            isInvalid={formik.touched.name && !!formik.errors.name}
                        />
                        <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label style={{ color: 'black' }}>Postal / Zip Code *</Form.Label>
                        <Form.Control
                            name="postalCode"
                            {...formik.getFieldProps('postalCode')}
                            isInvalid={formik.touched.postalCode && !!formik.errors.postalCode}
                        />
                        <Form.Control.Feedback type="invalid">{formik.errors.postalCode}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label style={{ color: 'black' }}>Street Address *</Form.Label>
                        <Form.Control
                            name="street"
                            {...formik.getFieldProps('street')}
                            isInvalid={formik.touched.street && !!formik.errors.street}
                        />
                        <Form.Control.Feedback type="invalid">{formik.errors.street}</Form.Control.Feedback>
                    </Form.Group>

                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ color: 'black' }}>Building No *</Form.Label>
                                <Form.Control
                                    name="building"
                                    {...formik.getFieldProps('building')}
                                    isInvalid={formik.touched.building && !!formik.errors.building}
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.building}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ color: 'black' }}>City *</Form.Label>
                                <Form.Control
                                    name="city"
                                    {...formik.getFieldProps('city')}
                                    isInvalid={formik.touched.city && !!formik.errors.city}
                                />
                                <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="countrySelect">
                                <Form.Label style={{ color: 'black' }}>Country</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={e => setSelectedCountry(e.target.value)}
                                    value={selectedCountry}
                                >
                                    <option value="">Select a country</option>
                                    {countries.map(country => (
                                        <option key={country.isoCode} value={country.isoCode}>
                                            {country.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="stateSelect">
                                <Form.Label style={{ color: 'black' }}>Province/State</Form.Label>
                                <Form.Control as="select">
                                    <option value="">Select a state</option>
                                    {states.map(state => (
                                        <option key={state.isoCode} value={state.isoCode}>
                                            {state.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-3">
                        <Form.Label style={{ color: 'black' }}>Location Type *</Form.Label>
                        <Form.Control
                            as="select"
                            name="locationType"
                            {...formik.getFieldProps('locationType')}
                            isInvalid={formik.touched.locationType && !!formik.errors.locationType}
                        >
                            <option value="" disabled>
                                Select
                            </option>
                            {locationsTypes.map(location => (
                                <option key={location.value} value={location.value}>
                                    {location.label}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{formik.errors.locationType}</Form.Control.Feedback>
                    </Form.Group>

                    {showResidentialAddressCheckbox && (
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        label="Residential Address"
                                        name="residentialAddress"
                                        {...formik.getFieldProps('residentialAddress')}
                                        isInvalid={
                                            formik.touched.residentialAddress && !!formik.errors.residentialAddress
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.residentialAddress}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ color: 'black' }}>
                                    {formType === 'from' ? 'Pickup Date *' : 'Dropoff Date *'}
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    {...formik.getFieldProps(formType === 'from' ? 'pickupDate' : 'dropoffDate')}
                                    name={formType === 'from' ? 'pickupDate' : 'dropoffDate'}
                                    isInvalid={
                                        formType === 'from'
                                            ? formik.touched.pickupDate && !!formik.errors.pickupDate
                                            : formik.touched.dropoffDate && !!formik.errors.dropoffDate
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formType === 'from' ? formik.errors.pickupDate : formik.errors.dropoffDate}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label style={{ color: 'black' }}>
                                    {formType === 'from' ? 'Pickup Time *' : 'Drop off Time *'}
                                </Form.Label>
                                <Form.Control
                                    type="time"
                                    {...formik.getFieldProps(formType === 'from' ? 'pickupTime' : 'dropoffTime')}
                                    name={formType === 'from' ? 'pickupTime' : 'dropoffTime'}
                                    isInvalid={
                                        formType === 'from'
                                            ? formik.touched.pickupTime && !!formik.errors.pickupTime
                                            : formik.touched.dropoffTime && !!formik.errors.dropoffTime
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formType === 'from' ? formik.errors.pickupTime : formik.errors.dropoffTime}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

export const ShippingForm = forwardRef(ShippingFormW);
