import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, ListGroup, Col, Form } from 'react-bootstrap';
import CustomAutomplete from '@/components/Default/Autocomplete';
import { useDispatch } from 'react-redux';
import { useModuleContext } from '../context';
import { useRequest } from '@/components/App';
import { useAuth } from '@/components/App';
import { useTranslate } from '@/components/Translate';

interface AddressBookProps {
    show: boolean;
    onHide: () => void;
    onSelectAddress: (address: any) => void;
    previousId?: string;
    setShowAddressForm: (show: boolean) => void;
}

const AddressBook: React.FC<AddressBookProps> = ({ show, onHide, onSelectAddress, previousId, setShowAddressForm }) => {
    const { request, loading } = useRequest();
    const { state, dispatch } = useModuleContext();
    const commonDispatch = useDispatch();
    const { trans } = useTranslate();

    // Get addresses from auth context
    const {
        default: { addresses: authAddresses },
    } = useAuth();

    const [searchText, setSearchText] = useState('');
    const [requestType, setRequestType] = useState('ADDRESS_FETCH_BY_TAG');
    const [customError, setCustomError] = useState(null);

    // Debug log to check if addresses are available
    useEffect(() => {
        if (show) {
            console.log('Auth addresses:', authAddresses);
        }
    }, [show, authAddresses]);

    // Function to fetch addresses by tag
    const searchAddressByTag = useCallback(
        async (tag: string) => {
            console.log(`Searching addresses with tag: "${tag}" using request type: ${requestType}`);
            try {
                const response = await request(requestType, { tag });
                console.log('Search response:', response);
            } catch (error) {
                console.error('Error searching addresses:', error);
            }
        },
        [requestType, request],
    );

    // Format addresses for display
    const formatAddresses = useCallback(
        (addressData: any) => {
            if (!addressData || !addressData.length) {
                return requestType === 'ADDRESS_FETCH_BY_TAG'
                    ? [
                          {
                              tag: (
                                  <p>
                                      No Address Found,{' '}
                                      <Button variant="link" className="p-0" onClick={() => setShowAddressForm(true)}>
                                          Click
                                      </Button>{' '}
                                      to create New
                                  </p>
                              ),
                              notfound: true,
                          },
                      ]
                    : [];
            }

            let formattedAddresses;
            if (requestType === 'CUSTOMER_SAP_BY_TAG') {
                formattedAddresses = addressData.map((mp: any) => ({
                    ...mp,
                    tag: mp?.CardName,
                    name: mp?.CardName,
                    phone: mp?.Phone,
                    city: mp?.City,
                    state: mp?.ShipToState,
                    country: mp?.Country,
                    address: mp?.Address,
                    zipcode: mp?.ZipCode,
                    buildingNo: '',
                    streetAddress: mp?.Address,
                    geoPoint: mp?.fullAddress?.geoPoint,
                }));
            } else {
                formattedAddresses = addressData.map((mp: any) => ({
                    ...mp,
                    city: mp?.fullAddress?.city,
                    state: mp?.fullAddress?.state,
                    name: mp?.fullAddress?.name,
                    phone: mp?.fullAddress?.phone,
                    country: mp?.fullAddress?.country,
                    address: mp?.fullAddress?.address,
                    zipcode: mp?.fullAddress?.zipcode,
                    buildingNo: mp?.fullAddress?.buildingNo,
                    streetAddress: mp?.fullAddress?.streetAddress,
                    location: mp?.fullAddress?.geoPoint?.coordinates?.toString(),
                }));
            }

            console.log('Formatted addresses:', formattedAddresses);
            return formattedAddresses;
        },
        [requestType],
    );

    // Handle click on Autocomplete
    const handleClick = useCallback(() => {
        searchAddressByTag('');
    }, [searchAddressByTag]);

    // Load addresses on component mount
    useEffect(() => {
        if (show) {
            searchAddressByTag('');
        }
    }, [show, searchAddressByTag]);

    // Filter addresses to remove previous selection
    const filteredAddresses = useCallback(() => {
        const formatted = formatAddresses(authAddresses);
        const filtered = formatted.filter((e: any) => !e?._id || e._id !== previousId);
        return filtered || [];
    }, [formatAddresses, authAddresses, previousId]);

    // Handle address type change
    const handleAddressTypeChange = (isWarehouse: boolean) => {
        const newType = isWarehouse ? 'WAREHOUSE_BY_TAG' : 'ADDRESS_FETCH_BY_TAG';
        setRequestType(newType);
        // Re-fetch addresses with new type
        searchAddressByTag('');
    };

    return (
        <>
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Address Book</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="addressType">
                        <Form.Label>Address Type</Form.Label>
                        <Form.Check
                            type="checkbox"
                            id="warehouse-checkbox"
                            label="Check for Warehouse"
                            checked={requestType === 'WAREHOUSE_BY_TAG'}
                            onChange={(e: any) => handleAddressTypeChange(e.target.checked)}
                        />
                    </Form.Group>

                    <Col className="mb-4">
                        <CustomAutomplete
                            label="Tag"
                            placeholder="Tag"
                            onClick={handleClick}
                            type="text"
                            loading={loading?.[requestType + '_LOADING'] || false}
                            clearOption={() => {
                                console.log('Clearing search');
                                setSearchText('');
                                commonDispatch({
                                    type: 'CLEAR_ADDRESSES',
                                });
                            }}
                            customError={customError}
                            name="tag"
                            multiple={false}
                            onSelect={(e, val) => {
                                console.log('Selected address:', val);

                                if (val.notfound) {
                                    console.log('No address found, showing address creation dialog');
                                    dispatch({
                                        type: 'SET_DATA',
                                        data: {
                                            ...state,
                                            showAddress: true,
                                        },
                                    });
                                    onHide();
                                    return '';
                                }

                                // Check if selected address is the same as previous
                                if (previousId && val._id === previousId) {
                                    console.log('Selected same address as previous, showing error');
                                    setCustomError('Pickup and dropoff warehouse cannot be same');
                                } else {
                                    setCustomError(null);
                                    console.log('Passing selected address to parent');
                                    onSelectAddress(val);
                                    onHide();
                                }

                                commonDispatch({
                                    type: 'CLEAR_ADDRESSES',
                                });

                                return val.tag as string;
                            }}
                            isOptionsEmpty={false}
                            filter={false}
                            value={searchText}
                            data={filteredAddresses()}
                            onChange={e => {
                                const value = e.target.value;
                                console.log(`Search text changed: "${value}"`);
                                setSearchText(value);
                                searchAddressByTag(value);
                            }}
                            getvalue={val => val.tag as string}
                        />
                        <Form.Text muted>{trans('SEARCH BY TAG')}</Form.Text>
                        {customError && <Form.Text className="text-danger">{customError}</Form.Text>}
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddressBook;
