import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const MAPBOX_URL = '/mapbox_api_url';
// function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
//   let timeoutId: NodeJS.Timeout;

//   return function (this: any, ...args: Parameters<T>): void {
//     // eslint-disable-next-line @typescript-eslint/no-this-alias
//     const context = this;
//     clearTimeout(timeoutId);
//     timeoutId = setTimeout(() => {
//       func.apply(context, args);
//     }, delay);
//   };
// }

export const getDirections = (originCoordinates: number[], destinationCoordinates: number[]) => {
    // const accessToken = process.env.REACT_APP_MAPBOX_KEY;
    const params = {
        alternatives: true,
        geometries: 'geojson',
        overview: 'full',
        steps: false,
        // access_token: accessToken,
    } as any;

    const API_URL = `${MAPBOX_URL}/directions/v5/mapbox/driving/${originCoordinates[0]},${originCoordinates[1]};${
        destinationCoordinates[0]
    },${destinationCoordinates[1]}?${new URLSearchParams(params).toString()}`;

    return axios
        .get(API_URL)
        .then(response => {
            const data = response.data;
            const route = data.routes[0];

            if (route) {
                return route.geometry;
            } else {
                throw new Error('No route found');
            }
        })
        .catch(error => {
            throw new Error(`Error fetching directions: ${error.message}`);
        });
};

export const getAutoCompleteOptions = (query: string) => {
    const params = {
        types: 'country,region,place,district,locality,postcode,neighborhood,address,poi,poi.landmark',
        // access_token: accessToken,
        session_token: uuidv4(),
    } as any;

    const API_URL = `${MAPBOX_URL}/geocoding/v5/mapbox.places/${query}.json?${new URLSearchParams(params).toString()}`;

    return axios
        .get(API_URL)
        .then(response => {
            const data = response.data;
            if (data?.features) return data?.features;
        })
        .catch(error => {
            throw new Error(`Mapbox Error in AutoComplete: ${error.message}`);
        });
};
