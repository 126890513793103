import React from 'react';
import CustomAccordion from './Accordian';
import { Col, Form, Row } from 'react-bootstrap';
import SelectComponent from './Select';

const InsuranceAccordion = ({
    selectedCurrency,
    setSelectedCurrency,
    selectedInsurance,
    handleSelectChange,
    insuranceOptions,
    amount = 0,
    setAmount,
}) => (
    <CustomAccordion heading="Additional Insurance">
        <p>Total Cost Value of Goods being Shipped</p>
        <Row className="mb-3">
            <Col sm={3}>
                <Form.Group controlId="formValue">
                    <Form.Label style={{ color: '#666' }}>Value of Goods($)</Form.Label>
                    <Form.Control
                        type="number"
                        value={amount}
                        placeholder="Enter value"
                        onChange={e => setAmount(e.target.value)}
                    />
                </Form.Group>
            </Col>
            <Col sm={6}>
                <Form.Group>
                    <Form.Label style={{ color: '#666' }}>Currency</Form.Label>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <Form.Check
                            inline
                            type="radio"
                            label="CAD"
                            name="currency"
                            checked={selectedCurrency === 'CAD'}
                            onChange={() => setSelectedCurrency('CAD')}
                            style={{ whiteSpace: 'nowrap' }}
                        />
                        <Form.Check
                            inline
                            type="radio"
                            label="USD"
                            name="currency"
                            checked={selectedCurrency === 'USD'}
                            onChange={() => setSelectedCurrency('USD')}
                            style={{ whiteSpace: 'nowrap' }}
                        />
                    </div>
                </Form.Group>
            </Col>
            <Col sm={3}>
                <SelectComponent
                    id="insuranceType"
                    name="insuranceType"
                    value={selectedInsurance}
                    options={insuranceOptions}
                    onChange={handleSelectChange}
                    label="Insurance Type"
                    className="insurance-type-select"
                />
            </Col>
        </Row>
    </CustomAccordion>
);

export default InsuranceAccordion;
