import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Col, Row, Tab, Tabs, Form, Card } from 'react-bootstrap';
import { BsGrid, BsBoxSeam, BsTruck, BsEnvelope } from 'react-icons/bs';
import Modal from '@/components/Default/Modal';

import { ShippingFormContainer } from './Components/addQoutefrom';
import UnitContainer from '@/pages/Orders/AddSalesOrder/Components/UnitContainer';
import InsuranceAccordion from './Components/InsurenceAccordian';
import SignatureOptions from './Components/SignatureOptions';
import CheckboxGroup from './Components/CheckboxGroup';
import Footer from './Components/Footer';
import Container from '@/pages/Container';
import { useRequest, useLoading } from '@/components/App';
import { ContextProvider, useModuleContext } from './context';
import { fieldsSet1, fieldsSet2, fieldsSet3, TABS } from './constants';
import AllQuoteUserList from '@/pages/Loads/Components/AllQuoteUserList';
import { REQUEST } from '@/store/interfaces';
import AddressForm from '@/pages/Address/Components/AddressForm';
import { CenteredModal } from '@/components/Default/CenteredModal/Modal';

// Types
interface TabConfig {
    key: string;
    title: string;
    icon: React.ElementType;
    fields: any[];
    showSignature?: boolean;
    showPalletServices?: boolean;
    showWeightOption?: boolean;
    showDimensionsWeight?: boolean;
    documentCheckbox?: boolean;
}

interface FormState {
    from: any;
    to: any;
    units?: any[];
    weightOption?: string;
    showRates?: boolean;
    selectedOption?: string;
    insurance?: InsuranceDetails;
    checkboxOptions?: Array<{
        label: string;
        value: string;
        checked: boolean;
        tooltipText: string;
    }>;
}
// New interfaces for API data structure
interface ShippingAddress {
    postalCode: string;
    city: string;
    province: string;
    country: string;
    isResidential?: boolean;
    name?: string;
    streetAddress?: string;
    buildingNo?: string;
    locationType?: string;
    pickupDate?: string;
    pickupTime?: string;
    dropoffDate?: string;
    dropoffTime?: string;
    geoPoint?: {
        type: string;
        coordinates: number[];
    };
}

interface Unit {
    length: number;
    width: number;
    height: number;
    weight: number;
    quantity: number;
    description?: string;
    freightClass?: string;
    type?: string;
    pieces?: number;
}

interface InsuranceDetails {
    type: string;
    currency: 'CAD' | 'USD';
    amount?: number;
}

interface ServiceOptions {
    dangerousGoods: boolean;
    stackable: boolean;
    limitedAccess: boolean;
    appointmentDelivery: boolean;
    thresholdDelivery: boolean;
    thresholdPickup: boolean;
    inBond: boolean;
    protectFromFreeze: boolean;
    tradeShowDelivery: boolean;
    amazonFBADelivery: boolean;
}

interface ShipmentData {
    type: string;
    shipmentFrom: ShippingAddress;
    shipmentTo: ShippingAddress;
    units: Unit[];
    measurementUnit: string;
    showPackageRates: boolean;
    serviceOptions: ServiceOptions;
    insurance: InsuranceDetails;
    signatureOption?: string;
    isDocumentOnly?: boolean | null;
}
const INITIAL_CHECKBOX_OPTIONS = [
    {
        label: 'Dangerous Goods',
        tooltipText: 'Learn More',
        value: 'dangerousGoods',
        checked: false,
    },
    {
        label: 'Stackable',
        tooltipText: 'Learn More',
        value: 'stackable',
        checked: false,
    },
    {
        label: 'Limited Access',
        tooltipText: 'Learn More',
        value: 'limitedAccess',
        checked: false,
    },
    {
        label: 'Appointment Delivery',
        tooltipText: 'Learn More',
        value: 'appointmentDelivery',
        checked: false,
    },
    {
        label: 'Threshold Delivery',
        tooltipText: 'Learn More',
        value: 'thresholdDelivery',
        checked: false,
    },
    {
        label: 'Threshold Pickup',
        tooltipText: 'Learn More',
        value: 'thresholdPickup',
        checked: false,
    },
    {
        label: 'In-Bond',
        tooltipText: 'Learn More',
        value: 'inBond',
        checked: false,
    },
    {
        label: 'Protect from Freeze',
        tooltipText: 'Learn More',
        value: 'protectFromFreeze',
        checked: false,
    },
    {
        label: 'Trade Show Delivery',
        tooltipText: 'Learn More',
        value: 'tradeShowDelivery',
        checked: false,
    },
    {
        label: 'Amazon/FBA Delivery',
        tooltipText: 'Learn More',
        value: 'amazonFBADelivery',
        checked: false,
    },
];

const TAB_CONFIG: TabConfig[] = [
    {
        key: 'pallet',
        title: 'Pallet',
        icon: BsGrid,
        fields: fieldsSet2,
        showPalletServices: true,
        showWeightOption: true,
        showDimensionsWeight: true,
    },
    {
        key: 'package',
        title: 'Package',
        icon: BsBoxSeam,
        fields: fieldsSet1,
        showSignature: true,
        showWeightOption: true,
        showDimensionsWeight: true,
    },
    {
        key: 'courier',
        title: 'Courier Pak',
        icon: BsTruck,
        fields: fieldsSet3,
        showSignature: true,
        showDimensionsWeight: true,
    },
    {
        key: 'envelope',
        title: 'Envelope',
        icon: BsEnvelope,
        fields: [],
        showSignature: true,
        documentCheckbox: true,
    },
];

type TabKeys = 'pallet' | 'package' | 'courier' | 'envelope';

function AddLoad() {
    const navigate = useNavigate();
    const { request } = useRequest();
    const { id } = useParams();
    const { state, dispatch } = useModuleContext();
    const [formData, setFormData] = useState<Record<string, FormState>>(() => {
        return TAB_CONFIG.reduce(
            (acc, tab) => ({
                ...acc,
                [tab.key]: {
                    from: {},
                    to: {},
                    weightOption: 'metric',
                    showRates: false,
                    selectedOption: 'no-signature',
                    insurance: {
                        currency: 'CAD',
                        type: '',
                    },
                    checkboxOptions: [...INITIAL_CHECKBOX_OPTIONS],
                },
            }),
            {},
        );
    });
    const [activeTab, setActiveTab] = useState('pallet');
    const [resetTrigger, setResetTrigger] = useState({
        pallet: 0,
        package: 0,
        courier: 0,
        envelope: 0,
    });
    const [showQuoteModal, setShowQuoteModal] = useState(false);
    const [quoteUserList, setQuoteUserList] = useState<{ firstname: string; _id: string; email: string }[]>([]);
    const [showAddress, setShowAddress] = useState(false);
    const [showAddressForm, setShowAddressForm] = useState(false);

    // Initialize form data for each tab
    useEffect(() => {
        const initialData = TAB_CONFIG.reduce(
            (acc, tab) => ({
                ...acc,
                [tab.key]: {
                    from: {},
                    to: {},
                    weightOption: 'metric',
                    showRates: false,
                    selectedOption: 'no-signature',
                    insurance: {
                        currency: 'CAD',
                        type: '',
                    },
                    checkboxOptions: [...INITIAL_CHECKBOX_OPTIONS],
                },
            }),
            {},
        );
        setFormData(initialData);
    }, []);

    const handleFormChange = (tabKey: string, fromData: any, toData: any) => {
        setFormData(prev => ({
            ...prev,
            [tabKey]: {
                ...prev[tabKey],
                from: fromData,
                to: toData,
            },
        }));
    };

    const handleUnitsChange = (tabKey: string, units: any[]) => {
        setFormData(prev => ({
            ...prev,
            [tabKey]: {
                ...prev[tabKey],
                units,
            },
        }));
    };

    const handleCheckboxChange = (tabKey: string, option: any) => {
        setFormData(prev => ({
            ...prev,
            [tabKey]: {
                ...prev[tabKey],
                checkboxOptions: prev[tabKey].checkboxOptions?.map(opt =>
                    opt.value === option.value ? { ...opt, checked: !opt.checked } : opt,
                ),
            },
        }));
    };

    const handleInsuranceChange = (tabKey: string, field: keyof InsuranceDetails, value: string | number) => {
        const updatedInsurance = {
            ...formData[tabKey].insurance,
            [field]: value,
        };
        setFormData(prev => ({
            ...prev,
            [tabKey]: {
                ...prev[tabKey],
                insurance: updatedInsurance as InsuranceDetails,
            },
        }));
    };

    const handleReset = () => {
        const initialData = TAB_CONFIG.reduce(
            (acc, tab) => ({
                ...acc,
                [tab.key]: {
                    from: {},
                    to: {},
                    weightOption: 'metric',
                    showRates: false,
                    selectedOption: 'no-signature',
                    insurance: {
                        currency: 'CAD',
                        type: '',
                    },
                    checkboxOptions: [...INITIAL_CHECKBOX_OPTIONS],
                },
            }),
            {},
        );
        setResetTrigger(prev => ({
            ...prev,
            [activeTab]: prev[activeTab as TabKeys] + 1,
        }));
        setFormData(initialData);
    };

    const transformCheckboxOptions = (options: any[]): ServiceOptions => {
        return options.reduce(
            (acc, option) => ({
                ...acc,
                [option.value]: option.checked,
            }),
            {
                dangerousGoods: false,
                stackable: false,
                limitedAccess: false,
                appointmentDelivery: false,
                thresholdDelivery: false,
                thresholdPickup: false,
                inBond: false,
                protectFromFreeze: false,
                tradeShowDelivery: false,
                amazonFBADelivery: false,
            },
        );
    };

    const transformToApiFormat = useCallback((formData: Record<string, FormState>): Record<string, ShipmentData> => {
        const apiData: Record<string, ShipmentData> = {};

        Object.entries(formData).forEach(([tabKey, data]) => {
            apiData[tabKey] = {
                type: tabKey.toUpperCase(),
                shipmentFrom: {
                    postalCode: data.from.postalCode || '',
                    city: data.from.city || '',
                    province: data.from.province || '',
                    country: data.from.country || '',
                    isResidential: data.from.isResidential || false,
                    name: data.from.name || '',
                    streetAddress: data.from.streetAddress || '',
                    buildingNo: data.from.buildingNo || '',
                    locationType: data.from.locationType || '',
                    pickupDate: data.from.pickupDate || '',
                    pickupTime: data.from.pickupTime || '',
                    dropoffDate: data.from.dropoffDate || '',
                    dropoffTime: data.from.dropoffTime || '',
                    geoPoint: data.from.geoPoint || {
                        type: '',
                        coordinates: [],
                    },
                },
                shipmentTo: {
                    postalCode: data.to.postalCode || '',
                    city: data.to.city || '',
                    province: data.to.province || '',
                    country: data.to.country || '',
                    isResidential: data.to.isResidential || false,
                    name: data.to.name || '',
                    streetAddress: data.to.streetAddress || '',
                    buildingNo: data.to.buildingNo || '',
                    locationType: data.to.locationType || '',
                    dropoffDate: data.to.dropoffDate || '',
                    dropoffTime: data.to.dropoffTime || '',
                    pickupDate: data.to.pickupDate || '',
                    pickupTime: data.to.pickupTime || '',
                    geoPoint: data.to.geoPoint || {
                        type: '',
                        coordinates: [],
                    },
                },
                units:
                    data.units?.map(unit => ({
                        length: Number(unit.length) || 0,
                        width: Number(unit.width) || 0,
                        height: Number(unit.height) || 0,
                        weight: Number(unit.weight) || 0,
                        quantity: Number(unit.quantity) || 1,
                        description: unit.description || '',
                        freightClass: unit.freightClass || '',
                        type: unit.type || '',
                        pieces: Number(unit.pieces) || 0,
                    })) || [],
                measurementUnit: data.weightOption || 'metric',
                showPackageRates: data.showRates || false,
                serviceOptions: transformCheckboxOptions(data.checkboxOptions || []),
                insurance: {
                    type: data.insurance?.type || '',
                    currency: data.insurance?.currency || 'CAD',
                    amount: data.insurance?.amount || 0,
                },
                signatureOption: data.selectedOption || 'no-signature',
                isDocumentOnly: tabKey === 'envelope' ? data.showRates : null,
            };
        });

        return apiData;
    }, []);

    const handleSubmit = async () => {
        try {
            const apiData = transformToApiFormat(formData);

            // Get the active tab's data
            const activeTabKey = Object.keys(apiData)[0];
            const shipmentData = apiData[activeTabKey];

            const req = (await request('CREATE_SALES_ORDER', shipmentData)) as REQUEST;
            if (req.status) {
                navigate('/sales-orders');
            }

            // console.log('Saved successfully:', response);
            return apiData;
        } catch (error) {
            console.error('Error submitting form:', error);
            throw error;
        }
    };

    const getFields = useCallback((fields: any[], weightOption: string | undefined) => {
        return fields.map(field => {
            const newField = { ...field };
            const lengthUnit = weightOption === 'metric' ? 'cm' : 'in';
            const weightUnit = weightOption === 'metric' ? 'kg' : 'lbs';

            if (newField.label.includes('Length')) {
                newField.label = `Length (${lengthUnit})`;
            } else if (newField.label.includes('Width')) {
                newField.label = `Width (${lengthUnit})`;
            } else if (newField.label.includes('Height')) {
                newField.label = `Height (${lengthUnit})`;
            } else if (newField.label.includes('Weight')) {
                newField.label = `Weight (${weightUnit})`;
            }

            return newField;
        });
    }, []);

    const renderTabContent = (config: TabConfig) => {
        const tabData = formData[config.key] || {
            from: {},
            to: {},
            weightOption: 'metric',
            showRates: false,
            selectedOption: 'no-signature',
            insurance: {
                currency: 'CAD',
                type: '',
            },
            checkboxOptions: [...INITIAL_CHECKBOX_OPTIONS],
        };
        return (
            <div
                style={{
                    marginLeft: '1%',
                    marginTop: '1%',
                }}
            >
                <p className="mb-3 text-gray-600 ml-4">
                    Please provide the Postal or ZIP Code of where you are shipping from and shipping to in order to get
                    a quote.
                </p>

                <ShippingFormContainer
                    onFormChange={(from, to) => handleFormChange(config.key, from, to)}
                    name={config.title}
                    resetTrigger={resetTrigger[config.key as keyof typeof resetTrigger]}
                    setShowAddressForm={setShowAddressForm}
                />

                {config.showDimensionsWeight && (
                    <div
                        className="mb-3 mx-auto"
                        style={{
                            marginTop: '1%',
                        }}
                    >
                        <div className="header-left">
                            <h2 className="mb-3 heading">Dimensions & Weight</h2>
                        </div>
                        <div className="line" />

                        <div className="d-flex justify-content-between align-items-center w-80">
                            <Form.Check
                                type="checkbox"
                                label={
                                    config.documentCheckbox
                                        ? 'I confirm this envelope only contains documents'
                                        : 'Show both pallet and package rates for this shipment'
                                }
                                checked={tabData.showRates}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        [config.key]: {
                                            ...prev[config.key],
                                            showRates: e.target.checked,
                                        },
                                    }))
                                }
                            />

                            {config.showWeightOption && (
                                <Form.Group>
                                    <div className="d-inline-flex align-items-center">
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Metric (cm & kg)"
                                            name={`measurement-${config.key}`}
                                            value="metric"
                                            checked={tabData.weightOption === 'metric'}
                                            onChange={() =>
                                                setFormData(prev => ({
                                                    ...prev,
                                                    [config.key]: {
                                                        ...prev[config.key],
                                                        weightOption: 'metric',
                                                    },
                                                }))
                                            }
                                            style={{ whiteSpace: 'nowrap' }}
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Imperial (in & lbs)"
                                            name={`measurement-${config.key}`}
                                            value="imperial"
                                            checked={tabData.weightOption === 'imperial'}
                                            onChange={() =>
                                                setFormData(prev => ({
                                                    ...prev,
                                                    [config.key]: {
                                                        ...prev[config.key],
                                                        weightOption: 'imperial',
                                                    },
                                                }))
                                            }
                                            style={{ whiteSpace: 'nowrap' }}
                                        />
                                    </div>
                                </Form.Group>
                            )}
                        </div>
                    </div>
                )}

                {config.fields.length > 0 && (
                    <UnitContainer
                        onUnitsChange={(key, units) => handleUnitsChange(config.key, units)}
                        fields={getFields(config.fields, tabData.weightOption)}
                        type={TABS[config.title as keyof typeof TABS]}
                    />
                )}

                {config.showPalletServices && (
                    <>
                        <div className="mb-3 mt-3">
                            <div className="line" />
                            <CheckboxGroup
                                options={tabData.checkboxOptions?.slice(0, 2) || INITIAL_CHECKBOX_OPTIONS.slice(0, 2)}
                                onChange={option => handleCheckboxChange(config.key, option)}
                            />
                        </div>

                        <div className="mb-3">
                            <h2 className="heading">Additional Services for Pallets</h2>
                            <div className="line-half" />
                            <CheckboxGroup
                                options={tabData.checkboxOptions?.slice(2, 6) || INITIAL_CHECKBOX_OPTIONS.slice(2, 6)}
                                onChange={option => handleCheckboxChange(config.key, option)}
                            />
                            <CheckboxGroup
                                options={tabData.checkboxOptions?.slice(6) || INITIAL_CHECKBOX_OPTIONS.slice(6)}
                                onChange={option => handleCheckboxChange(config.key, option)}
                            />
                        </div>
                    </>
                )}

                {config.showSignature && (
                    <div className="mb-3 w-97 ml-4 mt-3">
                        <SignatureOptions
                            selectedOption={tabData.selectedOption}
                            onChange={(value: string) =>
                                setFormData(prev => ({
                                    ...prev,
                                    [config.key]: {
                                        ...prev[config.key],
                                        selectedOption: value,
                                    },
                                }))
                            }
                        />
                    </div>
                )}

                <InsuranceAccordion
                    selectedCurrency={tabData.insurance?.currency}
                    setSelectedCurrency={(value: string) => handleInsuranceChange(config.key, 'currency', value)}
                    selectedInsurance={tabData.insurance?.type}
                    handleSelectChange={e => handleInsuranceChange(config.key, 'type', e.target.value)}
                    insuranceOptions={[
                        { value: 'internal', label: 'Catena' },
                        { value: 'carrier', label: 'Carrier' },
                    ]}
                    amount={tabData.insurance?.amount}
                    setAmount={(value: number) => handleInsuranceChange(config.key, 'amount', value)}
                />
            </div>
        );
    };

    const closeModal = () => {
        console.log('close modal');
        setShowQuoteModal(false);
    };

    const triggerQuoteModal = async () => {
        const res = (await request('GET_DEFAULT_DATA_MODEL', {
            pathname: '/user-manager/get-all-users',
            model: 'allusers',
            filter: {
                type: ['fleet', 'broker', 'ownerTrucker'],
            },
        })) as REQUEST;
        if (res.status) {
            setQuoteUserList((res.data as { firstname: string; _id: string; email: string }[]) || []);
        }
        if (res.status) {
            setTimeout(() => setShowQuoteModal(true), 0);
        }
    };
    const sendQuote = async (data: {
        quoteSuppliers: never[];
        suppliersProduct: string;
        quoteUsers: never[];
        _id: string | string[];
        emails: string;
        note: string;
    }) => {
        const apiData = transformToApiFormat(formData);
        const activeTabKey = Object.keys(apiData)[0];
        const { quoteSuppliers, suppliersProduct, ...rest } = data;
        const shipmentData = {
            ...apiData[activeTabKey],
            sendDetails: { ...rest },
        };
        const req = (await request('CREATE_SALES_ORDER', shipmentData)) as REQUEST;
        if (req.status) {
            navigate('/sales-orders');
        }

        // console.log('Saved successfully:', response);
        return apiData;
    };

    return (
        <Container title={id ? 'Edit Load' : 'Add Load'}>
            <Card className="p-3 w-98 mx-4">
                <h2 className="heading ml-4">Shipping Details</h2>
                <div className="line" />
                <p
                    className="mb-3 ml-4 text-gray-600"
                    style={{
                        marginLeft: '1%',
                        marginTop: '1%',
                    }}
                >
                    Please add the details of what you are shipping.
                </p>

                <Tabs
                    defaultActiveKey="pallet"
                    className="mb-3 custom-tabs custom-tab-title pb-12"
                    style={{
                        paddingBottom: '2%',
                    }}
                    onSelect={k => k && setActiveTab(k)}
                >
                    {TAB_CONFIG.map(config => (
                        <Tab
                            key={config.key}
                            eventKey={config.key}
                            title={
                                <>
                                    <config.icon className="me-2" />
                                    {config.title}
                                </>
                            }
                            style={{
                                marginTop: '10px',
                            }}
                        >
                            {activeTab === config.key && renderTabContent(config)}
                        </Tab>
                    ))}
                </Tabs>

                <Footer handleReset={handleReset} handleGetRates={triggerQuoteModal} handleSaveQuote={handleSubmit} />
            </Card>
            {/* <Modal
        id="sendQuoteModalRates"
        title={"Send Quotes to users"}
        show={showQuoteModal}
        // width="45%"
        centered={true}
        onClose={() => closeModal()}
      >
        <div className="container">
          <AllQuoteUserList
            onSubmit={() => {
              closeModal();
            }}
            loadId={state.loadId}
            loadSAPType={state.loadSAPType}
            data={quoteUserList || []}
            supplierProductData={state.supplierProduct || []}
            onFormSubmit={sendQuote}
          />
        </div>
      </Modal> */}
            <CenteredModal
                id="sendQuoteModal"
                key={state.showQuoteModal}
                title={'Send Quotes to users'}
                width={'800px'}
                size="lg"
                show={showQuoteModal}
                onClose={() => closeModal()}
                outer={true}
            >
                <AllQuoteUserList
                    onSubmit={() => {
                        closeModal();
                    }}
                    loadId={state.loadId}
                    loadSAPType={state.loadSAPType}
                    data={quoteUserList || []}
                    supplierProductData={state.supplierProduct || []}
                    onFormSubmit={sendQuote}
                    submitText="Save Quote"
                />
            </CenteredModal>
            <Modal
                id="address"
                title={'Add Address'}
                width="95%"
                show={showAddressForm}
                onClose={() => setShowAddressForm(false)}
            >
                <AddressForm component={() => setShowAddressForm(false)} />
            </Modal>
        </Container>
    );
}

export default function ShippingFormWrapper() {
    return (
        <ContextProvider>
            <AddLoad />
        </ContextProvider>
    );
}
