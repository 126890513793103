import React from 'react';
import { Button } from 'react-bootstrap';

const PalletActions = () => {
    return (
        <div className="item-controls-wrapper mt-3">
            <ul className="item-controls d-flex align-items-center list-unstyled">
                {/* My Pallets Button (Visible on Large Screens) */}
                <li className="d-none d-lg-block">
                    <Button variant="link" className="link-button-secondary">
                        <i className="fas fa-palette me-2" style={{ fontSize: '16px' }}></i>
                        My Pallets
                    </Button>
                </li>

                {/* Save Pallet Button */}
                <li>
                    <Button variant="link" className="link-button-secondary">
                        <i className="fas fa-save me-2" style={{ fontSize: '16px' }}></i>
                        Save Pallet
                    </Button>
                </li>

                {/* Clear Button */}
                <li>
                    <Button variant="link" className="link-button delete-button">
                        <i className="fas fa-remove me-2" style={{ fontSize: '16px', color: 'red' }}></i>
                        <span style={{ color: 'red' }}>Clear</span>
                    </Button>
                </li>

                {/* Complete Button (Visible on Small Screens) */}
                <li className="d-lg-none item-first-right-button">
                    <Button variant="primary">Complete</Button>
                </li>
            </ul>
        </div>
    );
};
export const PackageActions = () => {
    return (
        <div className="item-controls-wrapper mt-3">
            <ul className="item-controls d-flex align-items-center list-unstyled">
                {/* My Pallets Button (Visible on Large Screens) */}
                <li className="d-none d-lg-block">
                    <Button variant="link" className="link-button-secondary">
                        <i className="fas fa-package me-2" style={{ fontSize: '16px' }}></i>
                        My Packages
                    </Button>
                </li>

                {/* Save Pallet Button */}
                <li>
                    <Button variant="link" className="link-button-secondary">
                        <i className="fas fa-save me-2" style={{ fontSize: '16px' }}></i>
                        Save Package
                    </Button>
                </li>

                {/* Clear Button */}
                <li>
                    <Button variant="link" className="link-button delete-button">
                        <i className="fas fa-remove me-2" style={{ fontSize: '16px', color: 'red' }}></i>
                        <span style={{ color: 'red' }}>Clear</span>
                    </Button>
                </li>

                {/* Complete Button (Visible on Small Screens) */}
                <li className="d-lg-none item-first-right-button">
                    <Button variant="primary">Complete</Button>
                </li>
            </ul>
        </div>
    );
};
export const CourierPaksActions = () => {
    return (
        <div className="item-controls-wrapper mt-3">
            <ul className="item-controls d-flex align-items-center list-unstyled">
                {/* My Pallets Button (Visible on Large Screens) */}
                <li className="d-none d-lg-block">
                    <Button variant="link" className="link-button-secondary">
                        <i className="fas fa-courier me-2" style={{ fontSize: '16px' }}></i>
                        My Courier Paks
                    </Button>
                </li>

                {/* Save Pallet Button */}
                <li>
                    <Button variant="link" className="link-button-secondary">
                        <i className="fas fa-save me-2" style={{ fontSize: '16px' }}></i>
                        Save Courier Paks
                    </Button>
                </li>

                {/* Complete Button (Visible on Small Screens) */}
                <li className="d-lg-none item-first-right-button">
                    <Button variant="primary">Complete</Button>
                </li>
            </ul>
        </div>
    );
};

export default PalletActions;
