import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { useRequest } from '@/components/App';
import { REQUEST } from '@/store/interfaces';
import { usePermissions } from '@/pages/Container/PermissonContext';

interface ProtectedRouteProps {
    children: React.ReactNode;
    permission?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, permission = '' }) => {
    const { permissions } = usePermissions();
    if (permission && permissions.length > 0) {
        const hasPermission = permissions.includes(permission);
        if (!hasPermission) {
            return <Navigate to="/unauthorized" replace />;
        }
    }

    return <>{children}</>;
};

export default ProtectedRoute;
