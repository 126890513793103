import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';

const Unauthorized = () => {
    return (
        <Container fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <Row className="justify-content-center">
                <Col xs={12} sm={10} md={8} lg={6} xl={5}>
                    <Card className="shadow-lg border-0">
                        <Card.Body className="text-center p-5">
                            <div className="mb-4">
                                <FaLock size={50} className="text-danger" />
                            </div>
                            <Card.Title as="h1" className="mb-3">
                                Access Denied
                            </Card.Title>
                            <Card.Text className="text-muted mb-4">
                                You do not have permission to access this page. Please contact your administrator if you
                                believe this is an error.
                            </Card.Text>
                            <Button as={Link} to="/dashboard" variant="primary" size="lg" className="px-4">
                                Return to Dashboard
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Unauthorized;
