import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

// import { useTranslate } from '@/components/Translate';
import styles from '@/styles/Components/Addload/Addload.module.scss';
import { REQUEST } from '@/store/interfaces';
import { useRequest, useLoading, useAuth, useSettings } from '@/components/App';
import { phoneRegExp } from '@/utils/helpers';
import { useTranslate } from '@/components/Translate';
import CustomAutomplete from '@/components/Default/Autocomplete';

const Schema = {
    firstname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'), //.required('Required'),
    middlename: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
    lastname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
    email: Yup.string().email('Invalid email'),
    phone: Yup.string().matches(phoneRegExp, 'Phone Number is not Valid').min(9, 'Too Short!').max(11, 'Too Long!'),
    registeredOriginLocation: Yup.string().required('Required'),
    equipment: Yup.array().of(Yup.string()).min(1, 'At least one is Required').max(50, 'Maximum quantity Reached!'),
    // .required('Required'),
};

interface PROPS {
    [key: string]: unknown;
    component?: () => void;
    state?: {
        [key: string]: {
            [key: string]: any;
        };
    };
    edit?: boolean;
}
function index(props: PROPS) {
    const { ButtonLoader } = useLoading();
    const navigate = useNavigate();
    const { trans } = useTranslate();
    const { request, loading } = useRequest();
    const settings = useSettings();
    const { state } = props;
    const {
        default: { truck_equipments },
        auth: { profileDetail },
    } = useAuth();
    const getEquipmentList = useCallback(async () => {
        (await request('TRUCKS_GET_EQUIPMENT_LIST')) as REQUEST;
    }, []);
    useEffect(() => {
        getEquipmentList();
    }, []);
    const SignupSchema = Yup.object().shape(Schema);
    return (
        <div className={`${styles.addLoad} outer`}>
            <div className="w-100">
                <div className="cmnTable">
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            type: (state?.truckDetail?.type as string) || 'carrier',
                            _id: (state?.truckDetail?._id as string) || undefined,
                            companyName: (state?.truckDetail?.companyName || '') as string,
                            contactPerson: (state?.truckDetail?.contactPerson || '') as string,
                            companyPhone: (state?.truckDetail?.companyPhone || '') as string,
                            firstname: (state?.truckDetail?.firstname || '') as string,
                            middlename: (state?.truckDetail?.middlename || '') as string,
                            lastname: (state?.truckDetail?.lastname || '') as string,
                            phone: (state?.truckDetail?.phone || '') as string,
                            email: (state?.truckDetail?.email || '') as string,
                            email_cc: (state?.truckDetail?.email_cc || '') as string,
                            equipment: (state?.truckDetail?.equipment || []) as string[],
                            role_type: (state?.truckDetail?.role_type || 'Broker') as string,
                            registeredOriginLocation: (state?.truckDetail?.registeredOriginLocation ||
                                profileDetail?.companyCountry ||
                                '') as string,
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={async values => {
                            // same shape as initial values
                            if (request) {
                                if (props?.edit) {
                                    const req = (await request('TRUCKS_UPDATE_USER', values)) as REQUEST;
                                    if (req.status) {
                                        navigate('/trucks?type=carrier');
                                    }
                                } else {
                                    delete values._id;
                                    const req = (await request('TRUCKS_CREATE_USER', values)) as REQUEST;
                                    if (req.status) {
                                        if (props?.component) return props?.component();
                                        navigate('/trucks?type=carrier');
                                    }
                                }
                            }
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <div className={styles.addLoadField}>
                                    <h3>Carrier Information</h3>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicCompanyname">
                                                <Form.Label>{trans('Company Name *')}</Form.Label>
                                                <Form.Control
                                                    name="companyName"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Company name"
                                                    onChange={handleChange}
                                                    value={values.companyName}
                                                    isInvalid={!!errors.companyName}
                                                />
                                                {errors.companyName ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.companyName}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicContactPerson">
                                                <Form.Label>{trans('Contact Person *')}</Form.Label>
                                                <Form.Control
                                                    name="contactPerson"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Contact person"
                                                    onChange={handleChange}
                                                    value={values.contactPerson}
                                                    isInvalid={!!errors.contactPerson}
                                                />
                                                {errors.contactPerson ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.contactPerson}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicFirstname">
                                                <Form.Label>{trans('First name *')}</Form.Label>
                                                <Form.Control
                                                    name="firstname"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Firstname"
                                                    onChange={handleChange}
                                                    value={values.firstname}
                                                    isInvalid={!!errors.firstname}
                                                />
                                                {errors.firstname ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.firstname}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicMiddlename">
                                                <Form.Label>{trans('Middlename ')}</Form.Label>
                                                <Form.Control
                                                    name="middlename"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Middlename"
                                                    onChange={handleChange}
                                                    value={values.middlename}
                                                    isInvalid={!!errors.middlename}
                                                />
                                                {errors.middlename ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.middlename}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Col> */}
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicLastname">
                                                <Form.Label>{trans('Last name *')}</Form.Label>
                                                <Form.Control
                                                    name="lastname"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder="Lastname"
                                                    onChange={handleChange}
                                                    value={values.lastname}
                                                    isInvalid={!!errors.lastname}
                                                />

                                                {errors.lastname ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.lastname}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicPhone">
                                                <Form.Label>{trans('Mobile Number *')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    autoComplete="off"
                                                    name="phone"
                                                    placeholder="Mobile Number"
                                                    onChange={handleChange}
                                                    value={values.phone}
                                                    isInvalid={!!errors.phone}
                                                />
                                                {errors.phone ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.phone}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicCompanyPhone">
                                                <Form.Label>{trans('Telephone Number *')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    autoComplete="off"
                                                    name="companyPhone"
                                                    placeholder="Telephone Number"
                                                    onChange={handleChange}
                                                    value={values.companyPhone}
                                                    isInvalid={!!errors.companyPhone}
                                                />
                                                {errors.companyPhone ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.companyPhone}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>{trans('Email *')}</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email Address"
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    isInvalid={!!errors.email}
                                                />

                                                {errors.email ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>{trans('Registered Origin Location')}</Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                className="mb-3"
                                                name="registeredOriginLocation"
                                                onChange={handleChange}
                                                value={values.registeredOriginLocation}
                                                isInvalid={!!errors.registeredOriginLocation}
                                                // disabled
                                            >
                                                <option>Select Origin Location*</option>
                                                {settings.locations.map(loc => (
                                                    <option key={loc} value={loc}>
                                                        {loc}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {errors.registeredOriginLocation ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.registeredOriginLocation}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Col>
                                        <Col md={6} className={'mb-3'}>
                                            <CustomAutomplete
                                                label={'Equipments *'}
                                                placeholder={'Equipments'}
                                                type="text"
                                                loading={false}
                                                // onClick={() => disabledPairedFields()}
                                                clearOption={() => {
                                                    setFieldValue('equipment', []);
                                                }}
                                                name={'equipment'}
                                                multiple={true}
                                                onMultipleSelect={selected => {
                                                    if (selected.some(item => item.name === 'All')) {
                                                        setFieldValue(
                                                            'equipment',
                                                            truck_equipments.map(e => e.name),
                                                        );
                                                    } else {
                                                        setFieldValue('equipment', [...selected.map(mp => mp.name)]);
                                                    }
                                                }}
                                                onSelect={(e, val) => val.name as string}
                                                isOptionsEmpty={false}
                                                filter={true} //If not remote
                                                //Provide only if we want to render a value again
                                                values={values.equipment?.map(mp => ({ name: mp })) || []}
                                                data={[{ name: 'All' }, ...truck_equipments]}
                                                filterfrom={val => val.name as string}
                                                getvalue={val => val.name as string}
                                            />
                                            {errors.equipment ? (
                                                <Form.Text className="text-danger">{errors.equipment}</Form.Text>
                                            ) : null}
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicRoleType">
                                                <Form.Label>{trans('Role Type')}</Form.Label>
                                                <div className="d-flex gap-3">
                                                    {['Broker', 'Fleet', 'Owner'].map(role => (
                                                        <Form.Check
                                                            key={role}
                                                            type="checkbox"
                                                            name="role_type"
                                                            checked={values.role_type === role}
                                                            onChange={() => setFieldValue('role_type', role)}
                                                            isInvalid={!!errors?.role_type}
                                                            feedback={errors?.role_type}
                                                            feedbackType="invalid"
                                                            label={role}
                                                        />
                                                    ))}
                                                </div>
                                                {errors?.role_type && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.role_type}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="tableCheck mb-3" controlId="formBasictext">
                                                <Form.Label>{trans('CC')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={trans('Email CC')}
                                                    onChange={handleChange}
                                                    name="email_cc"
                                                    value={values.email_cc}
                                                    isInvalid={!!errors?.email_cc}
                                                />
                                                <Form.Text muted>
                                                    Please Provide multiple email Ids Comma Separated
                                                </Form.Text>
                                                {errors?.email_cc ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors?.email_cc}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                                <Col md={2} className="mt-3">
                                    {props?.edit ? (
                                        <Button type="submit" className="loginBtn customBtn">
                                            {loading?.TRUCKS_UPDATE_USER_LOADING ? ButtonLoader() : 'Update'}
                                        </Button>
                                    ) : (
                                        <Button type="submit" className="loginBtn customBtn">
                                            {loading?.TRUCKS_CREATE_USER_LOADING ? ButtonLoader() : 'Register'}
                                        </Button>
                                    )}
                                </Col>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default memo(index);
