import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from '@/styles/Components/Modal/Modal.module.scss';

interface CenteredModalProps {
    id: string;
    show: boolean;
    width?: string;
    title: string;
    children: React.ReactNode;
    onClose: () => void;
    outer?: boolean;
    centered?: boolean;
    backdrop?: 'static' | true | false;
    keyboard?: boolean;
    size?: 'sm' | 'lg' | 'xl';
}

const CenteredModal: React.FC<CenteredModalProps> = ({
    id,
    show,
    width = '500px',
    title,
    children,
    onClose,
    outer = false,
    centered = true,
    backdrop = true,
    keyboard = true,
    size,
}) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const [contentVisible, setContentVisible] = useState(false);

    // Control animation sequence
    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (show) {
            // Delay showing content to allow modal to open first
            timeout = setTimeout(() => {
                setContentVisible(true);
            }, 150); // Adjust this timing if needed
        } else {
            setContentVisible(false);
        }

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, [show]);

    // Apply custom width
    useEffect(() => {
        if (modalRef.current && show) {
            const modalDialog = modalRef.current.querySelector('.modal-dialog');
            if (modalDialog && width) {
                (modalDialog as HTMLElement).style.maxWidth = width;
                (modalDialog as HTMLElement).style.width = width;
            }
        }
    }, [show, width]);

    // Apply CSS transition for smooth height changes
    const contentStyle = {
        transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
        maxHeight: contentVisible ? '2000px' : '0px', // Adjust max value based on your content
        opacity: contentVisible ? 1 : 0,
        overflow: 'hidden',
    };

    return (
        <div ref={modalRef}>
            <Modal
                id={id}
                show={show}
                onHide={onClose}
                centered={centered}
                backdrop={backdrop}
                keyboard={keyboard}
                size={size}
                aria-labelledby={`modal-title-${id}`}
                className={styles.dynamicModal}
            >
                <div className={`${outer ? styles.outer : ''}`}>
                    <Modal.Header className={styles.rightModalbody}>
                        <Modal.Title id={`modal-title-${id}`}>{title}</Modal.Title>
                        <span onClick={onClose} style={{ cursor: 'pointer' }}>
                            <i className="fa fa-times text-danger" style={{ fontSize: '20px' }}></i>
                        </span>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={contentStyle}>{children}</div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
};

export { CenteredModal };
