import React, { useRef, useState, forwardRef, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BsArrowLeftRight } from 'react-icons/bs';

import CountrySelect from './selectCountry';
import StateSelect from './selectState';
import '../styles.css';
import { ShippingForm } from './ShippingForm';
import AddressBook from './AddAdress';

interface ShippingHeaderProps {
    text: string;
    leftIcon: React.ReactNode;
    rightIcon: React.ReactNode;
    formRef: React.RefObject<any>;
    setShowAddressForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShippingHeader: React.FC<ShippingHeaderProps> = ({ text, leftIcon, rightIcon, formRef, setShowAddressForm }) => {
    const [showAddressBook, setShowAddressBook] = useState(false);
    const handleAddressSelect = (address: any) => {
        if (formRef.current) {
            const setFieldValue = formRef.current.setFieldValue;
            setFieldValue('name', address.name || '');
            setFieldValue('street', address.address || '');
            setFieldValue('city', address.city || '');
            setFieldValue('building', address.buildingNo || '');
            setFieldValue('state', address.state || '');
            setFieldValue('postalCode', address.zipcode || '');
            setFieldValue('phone', address.phone || '');
            setFieldValue('countrySelect', address.country || '');
            setFieldValue('geoPoint', address.fullAddress.geoPoint || '');
        }

        setShowAddressBook(false);
    };
    return (
        <div className="shipping-header">
            <div className="header-left">
                <span className="header-icon">{leftIcon}</span>
                <h2 className="header-title">{text}</h2>
            </div>
            <div className="header-right">
                <span className="header-icon">{rightIcon}</span>
                <span className="address-book" onClick={() => setShowAddressBook(true)} style={{ cursor: 'pointer' }}>
                    Address Book
                </span>{' '}
            </div>
            <AddressBook
                show={showAddressBook}
                onHide={() => setShowAddressBook(false)}
                onSelectAddress={handleAddressSelect}
                previousId={'565656'}
                setShowAddressForm={setShowAddressForm}
            />
        </div>
    );
};

interface Props {
    showResidentialAddressCheckbox?: boolean;
    onFormChange?: (fromData: any, toData: any) => void;
}

export const ShippingFormContainer = ({
    showResidentialAddressCheckbox = true,
    onFormChange,
    name,
    resetTrigger,
    setShowAddressForm,
}: Props & {
    name: string;
    resetTrigger: number;
    setShowAddressForm: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const fromFormRef = useRef<any>(null);
    const toFormRef = useRef<any>(null);

    const handleFormChange = () => {
        if (onFormChange && fromFormRef.current && toFormRef.current) {
            onFormChange(fromFormRef.current.values, toFormRef.current.values);
        }
    };

    useEffect(() => {
        if (resetTrigger > 0) {
            // Reset form fields
            if (fromFormRef.current) {
                fromFormRef.current.resetForm();
            }
            if (toFormRef.current) {
                toFormRef.current.resetForm();
            }
        }
    }, [resetTrigger]);

    return (
        <div className="shipping-container d-flex justify-content-center align-items-center">
            {/* Left Form */}
            <div className="shipping-form">
                <ShippingHeader
                    text="Shipping From"
                    leftIcon={<i className="fas fa-sign-out"></i>}
                    rightIcon={<i className="fas fa-book"></i>}
                    formRef={fromFormRef}
                    setShowAddressForm={setShowAddressForm}
                />
                <ShippingForm
                    ref={fromFormRef}
                    showResidentialAddressCheckbox={showResidentialAddressCheckbox}
                    onChange={handleFormChange}
                    key={`${name}-shipping-from`}
                    formType="from"
                />
            </div>

            {/* Middle Button */}
            <div className="switch-button mx-3">
                <Button variant="outline-secondary" className="rounded-circle p-3">
                    <i className="fas fa-sync" style={{ fontSize: '24px' }}></i>
                </Button>
            </div>

            {/* Right Form */}
            <div className="shipping-form">
                <ShippingHeader
                    text="Shipping To"
                    leftIcon={<i className="fas fa-sign-in"></i>}
                    rightIcon={<i className="fas fa-book"></i>}
                    formRef={toFormRef}
                    setShowAddressForm={setShowAddressForm}
                />
                <ShippingForm
                    ref={toFormRef}
                    showResidentialAddressCheckbox={showResidentialAddressCheckbox}
                    onChange={handleFormChange}
                    key={`${name}-shipping-to`}
                    formType="to"
                />
            </div>
        </div>
    );
};

export default ShippingForm;
