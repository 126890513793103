import React, { lazy, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col } from 'react-bootstrap';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

import { ContainerContextProvider } from './context';

import Tooltip from '@/components/Default/Tooltip';
import styles from '@/styles/Components/Container/SideBar.module.scss';
import { useTranslate } from '@/components/Translate';
import { logout, toastr } from '@/utils/helpers';
import { REQUEST } from '@/store/interfaces';
import { useRequest } from '@/components/App';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { usePermissions } from './PermissonContext';
const Header = lazy(() => import('./Components/Header'));
const Footer = lazy(() => import('./Components/Footer'));

type Props = {
    header?: boolean | false;
    children: JSX.Element | string | JSX.Element[];
    footer?: boolean | false;
    title: string;
    content?: string;
};

type SidebarProps = {
    permissions: string[];
};

const Sidebar = React.memo(({ permissions }: SidebarProps) => {
    const navigate = useNavigate();
    const [expandedItems, setExpandedItems] = useState(() => {
        // Try to get from localStorage first
        const savedItems = localStorage.getItem('expandedMenuItems');
        if (savedItems) {
            try {
                return JSON.parse(savedItems);
            } catch (e) {
                console.error('Error parsing saved menu state', e);
            }
        }
        return [];
    });
    const { pathname } = useLocation();
    const { trans } = useTranslate();
    // Auto-expand parent items based on current path
    useEffect(() => {
        const itemsToExpand: unknown[] = [];

        // Find all parent items that should be expanded based on current path
        listData.forEach(item => {
            if (item.children) {
                const hasActiveChild = item.children.some(child => pathname.includes(child.path) && child.path !== '');

                if (hasActiveChild && !expandedItems.includes(item.id)) {
                    itemsToExpand.push(item.id);
                }
            }
        });

        // If we found items that should be expanded, update state
        if (itemsToExpand.length > 0) {
            const newExpandedItems = [...expandedItems, ...itemsToExpand];
            setExpandedItems(newExpandedItems);
            localStorage.setItem('expandedMenuItems', JSON.stringify(newExpandedItems));
        }
    }, [pathname]);

    // Save expanded state to localStorage when it changes
    useEffect(() => {
        localStorage.setItem('expandedMenuItems', JSON.stringify(expandedItems));
    }, [expandedItems]);
    const coming_Soon = () => {
        toastr('Coming Soon', 'info');
    };

    const listData = [
        {
            id: 1,
            name: trans('DASHBOARD'),
            icon: '/assets/images/bar-chart.svg',
            path: '/dashboard',
        },
        {
            id: 2,
            name: trans('Address Book'),
            protected: true,
            permission: 'address',
            icon: '/assets/images/addressed.svg',
            path: '/address',
        },
        {
            id: 3,
            name: trans('Carrier'),
            protected: true,
            permission: 'carrier',
            icon: '/assets/images/box.svg',
            // path: '/trucks',
            path: '/trucks?type=carrier',
        },
        {
            id: 4,
            name: trans('Sales Orders'),
            protected: true,
            permission: 'salesOrders',
            icon: '/assets/images/3d-square.svg',
            path: '',
            children: [
                {
                    id: 12,
                    name: trans('Sales Orders'),
                    path: '/sales-orders',
                    icon: '',
                },
                {
                    id: 13,
                    name: trans('Risk of Delay'),
                    path: '/orders/risk-delayed',
                    icon: '',
                },
                {
                    id: 14,
                    name: trans('Delayed Orders'),
                    path: '/orders/delayed',
                    icon: '',
                },
            ],
        },
        // {
        //     id: 4,
        //     name: trans('Sales Orders'),
        //     icon: '/assets/images/3d-square.svg',
        //     path: '/orders',
        // },
        {
            id: 5,
            name: trans('Purchase Orders'),
            protected: true,
            permission: 'purchaseOrders',
            icon: '/assets/images/3d-square.svg',
            path: '',
            children: [
                {
                    id: 16,
                    name: trans('Purchase Orders'),
                    path: '/purchase-orders',
                    icon: '',
                },
                { id: 17, name: trans('MapBox'), path: '/mapbox', icon: '' },
                { id: 18, name: trans('Micro View'), path: '/micro-view', icon: '' },
            ],
        },
        // {
        //     id: 6,
        //     name: trans('MapBox'),
        //     icon: '/assets/images/capa.svg',
        //     path: '/mapbox',
        // },
        // {
        //     id: 7,
        //     name: trans('Micro View'),
        //     icon: '/assets/images/capa.svg',
        //     path: '/micro-view',
        // },
        // {
        //   id: 8,
        //   name: trans('Container'),
        //   icon: '/assets/images/capa.svg',
        //   path: '',
        //   children: [
        //     { id: 12, name: trans('MapBox'), path: '/mapbox', icon: '' },
        //     { id: 13, name: trans('Micro View'), path: '/micro-view', icon: '' },
        //   ],
        // },
        {
            id: 9,
            name: trans('Schedule'),
            protected: true,
            permission: 'schedule',
            icon: '/assets/images/schedule.svg',
            path: '',
            children: [
                {
                    id: 12,
                    name: trans('Delivery Schedule'),
                    path: '/calendar',
                    icon: '',
                },
                {
                    id: 13,
                    name: trans('Drivers Schedule'),
                    path: '/delivery-schedule',
                    icon: '',
                },
                // { id: 13, name: trans('Bulk Loads Schedule'), path: '/bulk-loads-schedule', icon: '' },
            ],
        },
        // {
        //     id: 9,
        //     name: trans('Schedule'),
        //     icon: '/assets/images/schedule.svg',
        //     path: '/delivery-schedule',
        // },
        {
            id: 10,
            name: trans('Favorites'),
            protected: true,
            permission: 'favorites',
            icon: '/assets/images/favorites.svg',
            path: '/favourites',
        },
        {
            id: 11,
            name: trans('Reports'),
            protected: true,
            permission: 'reports',
            icon: '/assets/images/note.svg',
            path: '/reports',
        },
        {
            id: 12,
            name: trans('Analytics'),
            protected: true,
            permission: 'analytics',
            icon: '/assets/images/analytics.svg',
            path: '/comingSoon',
            // onClick: coming_Soon,
        },
        {
            id: 13,
            name: trans('Profile'),
            icon: '/assets/images/user-square.svg',
            path: '/profile',
        },
    ];

    const allowedItems = useMemo(() => {
        return listData.filter(item => !item.protected || (item.protected && permissions.includes(item.permission)));
    }, [permissions]);

    const sideNavBottomList = [
        {
            id: 1,
            name: trans('Setting'),
            icon: '/assets/images/settings.svg',
            path: '/comingSoon',
        },
        {
            id: 2,
            name: trans('Log out'),
            icon: '/assets/images/logout.svg',
            path: '',
            onClick: logout,
        },
    ];

    const toggleExpand = itemId => {
        const isExpanded = expandedItems.includes(itemId);
        let newExpandedItems;

        if (!isExpanded) {
            // Close all other expanded items and only open the current one
            newExpandedItems = [itemId];

            // If this is a child item, also include its parent
            const parentItem = listData.find(item => item.children && item.children.some(child => child.id === itemId));

            if (parentItem) {
                newExpandedItems = [parentItem.id, itemId];
            }
        } else {
            // If clicking an already expanded item, close it
            newExpandedItems = expandedItems.filter(id => id !== itemId);
        }

        setExpandedItems(newExpandedItems);
        localStorage.setItem('expandedMenuItems', JSON.stringify(newExpandedItems));
    };

    function isNavActive(path) {
        if (pathname.includes(path) && path !== '') return styles.active;
    }

    return (
        <div className={`${styles.slideContainer}`}>
            <div className={styles.sidebarContent}>
                <div className={`${styles.logoContainer}`}>
                    <Link to="/dashboard" className={styles.logo}>
                        <img width={150} src="/assets/images/logo.png" alt="logo" />
                    </Link>
                </div>

                <div className={`${styles.navContainer}`}>
                    <Nav style={{ gap: '12px' }}>
                        {allowedItems.map(item => (
                            <Nav.Item key={item.id} className={`${styles.navItem}`}>
                                <div
                                    onClick={() => {
                                        if (item?.onClick && typeof item.onClick === 'function') {
                                            item.onClick();
                                        } else {
                                            toggleExpand(item.id);
                                        }
                                    }}
                                    className={`${styles.navParentItem} ${isNavActive(item.path)}`}
                                >
                                    <img
                                        src={item.icon}
                                        alt="Icon"
                                        className="mr-2"
                                        style={{ width: '20px', height: '20px' }}
                                    />
                                    <Nav.Link as={NavLink} to={item.path} className={`${styles.navItemText}`}>
                                        {item.name}
                                    </Nav.Link>
                                    {item.children && (
                                        <img
                                            src={'/assets/images/angle-down-solid.svg'}
                                            alt="Icon"
                                            className="mr-2"
                                            style={{ width: '17px', height: '17px' }}
                                        />
                                    )}
                                </div>
                                {item.children && expandedItems.includes(item.id) && (
                                    <Nav style={{ paddingLeft: '30px' }}>
                                        {item.children.map(child => (
                                            <Nav.Item key={child.id} className={`${styles.navItem}`}>
                                                <div
                                                    onClick={() => toggleExpand(child.id)}
                                                    className={`${styles.navParentItem} ${isNavActive(child.path)}`}
                                                >
                                                    <Nav.Link
                                                        as={NavLink}
                                                        to={child.path}
                                                        className={`${styles.navItemText}`}
                                                    >
                                                        {child.name}
                                                    </Nav.Link>
                                                </div>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                                )}
                            </Nav.Item>
                        ))}
                    </Nav>
                    <div className={`${styles.subActions}`}></div>
                </div>
                <div className={`${styles.navContainer}`}>
                    <Nav style={{ gap: '12px' }}>
                        {sideNavBottomList.map(item => (
                            <Nav.Item key={item.id} className={`${styles.navItem}`}>
                                <div
                                    className={`${styles.navParentItem} ${isNavActive(item.path)}`}
                                    onClick={item.onClick}
                                >
                                    <img
                                        src={item.icon}
                                        alt="Icon"
                                        className="mr-2"
                                        style={{ width: '20px', height: '20px' }}
                                    />
                                    <Nav.Link as={NavLink} to={item.path} className={`${styles.navItemText}`}>
                                        {item.name}
                                    </Nav.Link>
                                </div>
                            </Nav.Item>
                        ))}
                    </Nav>
                </div>
            </div>
        </div>
    );
});
Sidebar.displayName = 'Sidebar';

const Container = React.memo(({ children, header, title, content }: Props) => {
    const navigate = useNavigate();
    const { permissions } = usePermissions();

    return (
        <ContainerContextProvider>
            <div className={`${styles.container}`}>
                <Sidebar permissions={permissions} />
                <div className={`${styles.mainContainer}`}>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={content || 'Catena Logistix'} />
                    </Helmet>
                    {!header && <Header />}
                    {window?.location?.pathname?.split('/')?.length > 2 && (
                        <div className="goBackContainer">
                            <Row>
                                <Col md={3}>
                                    <Tooltip text="Go Back">
                                        <i
                                            role="button"
                                            onClick={() => navigate(-1)}
                                            className="fa fa-arrow-left goBack"
                                        ></i>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </div>
                    )}

                    {children}
                    {<Footer />}
                </div>
            </div>
        </ContainerContextProvider>
    );
});

Container.displayName = 'Container';

export default Container;
