import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useRequest } from '@/components/App';
import { RootState } from '@/store';
import { useSelector } from 'react-redux';
import { REQUEST } from '@/store/interfaces';

// Create context
const PermissionsContext = createContext<{
    permissions: string[];
    loading: boolean;
}>({
    permissions: [],
    loading: true,
});

// Create provider
export const PermissionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [permissions, setPermissions] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const { request } = useRequest();
    const { user } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        const getPermissions = async () => {
            if (user?.companyId) {
                try {
                    const res = (await request('GET_USER_PERMISSION', { userId: user.companyId })) as REQUEST;

                    if (res?.data) {
                        const permissionKeys = res.data
                            .filter((item: any) => item.isGranted)
                            .map((item: any) => item.moduleId?.moduleKey);
                        setPermissions(permissionKeys);
                    } else {
                        setPermissions([]);
                    }
                } catch (error) {
                    console.error('Error fetching permissions:', error);
                    setPermissions([]);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        getPermissions();
    }, [user?.companyId]);

    const memoizedPermissions = useMemo(() => ({ permissions, loading }), [permissions, loading]);

    return <PermissionsContext.Provider value={memoizedPermissions}>{children}</PermissionsContext.Provider>;
};

// Create hook for easy access
export const usePermissions = () => useContext(PermissionsContext);
