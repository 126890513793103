import React from 'react';
import { Form } from 'react-bootstrap';

const SelectComponent = ({ id, name, value, options, onChange, label, className }) => {
    return (
        <Form.Group>
            {label && (
                <Form.Label htmlFor={id} style={{ color: '#666' }}>
                    {label}
                </Form.Label>
            )}
            <Form.Select id={id} name={name} value={value} onChange={onChange} className={className || 'custom-select'}>
                <option value="" disabled={true}>
                    Select{' '}
                </option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );
};

export default SelectComponent;
