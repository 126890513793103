import { Form, Button, Row, Col } from 'react-bootstrap';
import React from 'react';
import '../styles.css';
import { BsTrash } from 'react-icons/bs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const UnitForm: React.FC<UnitFormProps> = React.memo(({ index, unit, updateUnit, removeUnit, fields }) => {
    return (
        <Row className="p-3">
            {fields.map(field => (
                <Col md={3} key={field.name} className="mb-3">
                    <Form.Group>
                        {field.type !== 'check-box' && (
                            <Form.Label className="text" style={{ color: 'black' }}>
                                {field.label}
                            </Form.Label>
                        )}
                        {field.type === 'select' && field.options ? (
                            <Form.Select
                                value={unit[field.name] || ''}
                                onChange={e => updateUnit(index, field.name, e.target.value)}
                                className="form-control text"
                            >
                                <option value="">Select {field.label}</option>
                                {field.options.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        ) : field.type === 'number' ? (
                            <Form.Control
                                type="number"
                                value={unit[field.name] || ''}
                                onChange={e => updateUnit(index, field.name, e.target.value)}
                            />
                        ) : field.type === 'check-box' ? (
                            <Form.Check
                                type="checkbox"
                                label={field.label}
                                checked={unit[field.name] as boolean}
                                onChange={e => {
                                    updateUnit(index, field.name, e.target.checked);
                                }}
                            />
                        ) : (
                            <Form.Control
                                type="text"
                                value={(unit[field.name] as string) || ''}
                                onChange={e => updateUnit(index, field.name, e.target.value)}
                            />
                        )}
                    </Form.Group>
                </Col>
            ))}
            {index > 0 && (
                <Col md={12} className="d-flex justify-content-end">
                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-remove-${index}`}>Remove</Tooltip>}>
                        <BsTrash
                            onClick={() => removeUnit(index)}
                            style={{ cursor: 'pointer', color: 'red', fontSize: '1.2rem' }}
                        />
                    </OverlayTrigger>
                </Col>
            )}
        </Row>
    );
});

// display name
UnitForm.displayName = 'UnitForm';

export default UnitForm;
