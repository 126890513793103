import { Col, Form, Row } from 'react-bootstrap';
import React from 'react';
const SignatureOptions = ({ selectedOption, onChange }) => (
    <div className="signature-required">
        <h6>Signature Required?</h6>
        <Row className="mb-3">
            <Col sm={12}>
                <Form.Group>
                    <div className="d-flex align-items-center gap-3">
                        <Form.Check
                            inline
                            type="radio"
                            label="No Signature Required"
                            name="signature"
                            checked={selectedOption === 'no-signature'}
                            onChange={() => onChange('no-signature')}
                            style={{ whiteSpace: 'nowrap' }}
                        />
                        <Form.Check
                            inline
                            type="radio"
                            label="Signature Required"
                            name="signature"
                            checked={selectedOption === 'signature'}
                            onChange={() => onChange('signature')}
                            style={{ whiteSpace: 'nowrap' }}
                        />
                        <Form.Check
                            inline
                            type="radio"
                            label="Adult Signature Required"
                            name="signature"
                            checked={selectedOption === 'adult-signature'}
                            onChange={() => onChange('adult-signature')}
                            style={{ whiteSpace: 'nowrap' }}
                        />
                    </div>
                </Form.Group>
            </Col>
        </Row>
    </div>
);

export default SignatureOptions;
